<template>
  <v-dialog v-model="dialog" scrollable :width="getWidth">
    <v-card class="g-dialog_card pa-4" :class="className">
      <v-card-title class="g-dialog_title" v-if="title">
        <slot name="title">
          <v-flex>
            <v-layout justify-start align-center row wrap fill-height>
              {{ title }}
            </v-layout>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex>
            <v-layout justify-end align-center class="close-wrapper">
              <v-tooltip v-model="tooltip" bottom v-if="guides !== undefined && guides.length > 0">
                <template v-slot:activator="{ on }">
                  <!--<v-icon v-on="on" @click="tooltipDisplay" style="margin-right:8px;">check</v-icon>-->
                  <img :src="images.guide" class="svg_icon cursor" @click="tooltipDisplay" style="margin-right:16px;"/>
                </template>
                <div v-for="(item, index) in guides" :key="index">
                  {{ item }}
                </div>
              </v-tooltip>
              <img :src="images.close" class="svg_icon cursor" @click="dialog = false"/>
            </v-layout>
          </v-flex>
        </slot>
      </v-card-title>

      <v-card-text>
        <slot name="text"></slot>
      </v-card-text>

      <v-card-actions>
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { setTimeout, clearTimeout } from 'timers'
export default {
  name: 'GDialog',
  props: {
    model: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false
    },
    guides: {
      type: Array,
      required: false
    },
    width: {
      required: false
    },
    className: {
      type: String,
      required: false
    }
  },
  data: () => ({
    dialog: false,
    images: {
      close: require('@/assets/images/sub/popup_close.svg'),
      guide: require('@/assets/images/sub/icon_guide.svg')
    },
    tooltip: false,
    firstClick: true,
    timer: null
  }),
  created () {
  },
  mounted () {
  },
  watch: {
    model (val) {
      this.dialog = val
    },
    dialog (val) {
      if (!val) {
        this.$emit('close')
      }
    },
    tooltip (val) {
      if (val) {
        this.timer = setTimeout(function () {
          this.tooltip = false
        }.bind(this), 5000)
      } else {
        if (this.timer != null) {
          clearTimeout(this.timer)
          this.timer = null
        }
      }
    }
  },
  computed: {
    getWidth: function () {
      return ((this.width !== undefined && this.width !== '') ? this.width : 327)
    }
  },
  methods: {
    tooltipDisplay: function () {
      if (this.firstClick) {
        this.tooltip = true
        this.firstClick = false
      } else {
        this.tooltip = !this.tooltip
      }
    }
  }
}
</script>

<style scoped>
.g-dialog_card {overflow: hidden;}
.g-dialog_card >>> .v-card__title {padding:0 0 0 15px;height:56px;line-height:56px;background-color: #fff;font-size: 20px;font-weight: normal;font-style: normal;font-stretch: normal;letter-spacing: normal;color: #fff;}
.g-dialog_card >>> .v-card__title .close-wrapper {margin-right:16px;}

.g-dialog_card >>> .v-card__text {color:#888;background-color:#fff;/* padding:1px 0; */}

.g-dialog_card >>> .v-card__actions {padding:0;height: 48px;line-height: 48px;}
.g-dialog_card >>> .v-card__actions button {height: 100%;border:0;border-radius:0;font-size: 16px;font-weight: normal;font-style: normal;font-stretch: normal;letter-spacing: normal;text-align: center;}
.g-dialog_card >>> .v-card__actions .v-btn + .v-btn {margin:0;}
.g-dialog_card >>> .v-card__actions .v-btn:not(.v-btn--depressed):not(.v-btn--flat) {box-shadow:none;-webkit-box-shadow:none;}

.v-tooltip__content {padding: 16px 16px;box-shadow: 0 2px 4px 0 #00000033;border: solid 1px #979797;background-image: linear-gradient(to bottom, #ffffff, #f4f4f4);font-size: 12px;color: #444444;font-weight: normal;font-style: normal;font-stretch: normal;line-height: normal;letter-spacing: normal;}
.v-tooltip__content.menuable__content__active {opacity: 1 !important;}
</style>
