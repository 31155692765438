const MyPlugin = {
  install (Vue, options) {
    // 유효성 검증
    window.formValidate = {
      // 서버 응답 데이터 유효성 체크
      check: function (data, $refs) {
        try {
          if (data.validateFlag === undefined || data.errorList === undefined) {
            return true
          }
          if (data.validateFlag != null) {
            if (data.errorList != null && data.errorList.length > 0) {
              for (var i = 0; i < data.errorList.length; i++) {
                const fieldName = data.errorList[i].field
                const message = data.errorList[i].message
                if ($refs[fieldName] !== undefined) {
                  $refs[fieldName].setErrors([message])
                } else {
                  if (window.selfObj.$store !== undefined && window.selfObj.$store !== null) {
                    window.selfObj.$store.dispatch('snackbarShow', [message, null, false])
                  }
                }
              }
            }
            return false
          }
        } catch (e) {
          console.log(e)
        }
        return true
      }
    }
    // 헥사값 변경
    window.getHex = function (str) {
      var str16 = ''
      for (var i = 0; i < str.length; i++) {
        str16 += '' + str.charCodeAt(i).toString(16)
      }
      return str16
    }

    // 요청 데이터 암호화 하기
    window.getEncryptData = function (key, iv, info) {
      var CryptoJS = require('crypto-js')
      var cryptOptions = { }
      cryptOptions.iv = CryptoJS.enc.Hex.parse(iv)
      return CryptoJS.AES.encrypt(info, CryptoJS.enc.Hex.parse(key), cryptOptions).toString()
    }

    // 응답 데이터 복호화 하기
    window.getDecryptData = function (key, iv, info) {
      var CryptoJS = require('crypto-js')
      var cryptOptions = { }
      cryptOptions.iv = CryptoJS.enc.Hex.parse(iv)
      return CryptoJS.AES.decrypt(info, CryptoJS.enc.Hex.parse(key), cryptOptions).toString(CryptoJS.enc.Utf8)
    }

    // 문자열 암호화
    window.getEncryptStr = function (key, iv, info) {
      var CryptoJS = require('crypto-js')
      var cryptOptions = { }
      cryptOptions.iv = CryptoJS.enc.Hex.parse(iv)
      return CryptoJS.AES.encrypt(info, CryptoJS.enc.Hex.parse(key), cryptOptions).toString()
    }
    // 문자열 복호화
    window.getDecryptStr = function (key, iv, info) {
      var CryptoJS = require('crypto-js')
      var cryptOptions = { }
      cryptOptions.iv = CryptoJS.enc.Hex.parse(iv)
      var bytes = CryptoJS.AES.decrypt(info, CryptoJS.enc.Hex.parse(key), cryptOptions)
      var decrypted = bytes.toString(CryptoJS.enc.Utf8)
      return decrypted
    }

    /*
    // jquery ajax setting
    $.ajaxSetup({ cache: false })

    // jquery ajax func
    Vue.ajaxAction = function (typeVal, urlVal, param, dataTypeVal, successFnc, jsonParamFlag, beforeSendFnc) {
      var _contentType = 'application/x-www-form-urlencoded'
      if (!(jsonParamFlag === undefined) && jsonParamFlag != null && jsonParamFlag) {
        _contentType = 'application/json'
      }
      $.ajax({
        type: typeVal,
        contentType: _contentType,
        url: urlVal,
        data: param,
        dataType: (dataTypeVal != null ? dataTypeVal : 'json'),
        crossDomain: true,
        beforeSend: beforeSendFnc
      })
        .done(successFnc)
        .fail(function (jqXHR, textStatus, errorThrown) {
          console.log('code:' + jqXHR.status + ', message:' + jqXHR.responseText + ', error:' + errorThrown)
        })
    }
    */

    Vue.mixin({
      mounted () { // component 호출 시 항상 호출 된 후, component mount 호출
        window.selfObj = this
      }
    })
  }
}
export default MyPlugin
