<template>
  <!-- footer : start -->
<v-footer inset height="64" class="mainContentsFooter">
    <v-layout justify-center row wrap fill-height>
      <v-flex text-xs-center>
        <span :class="[this.$vuetify.breakpoint.width < 560 ? 'moreNowrapTxt':'']">{{ $t('texts.common.copyright') }}</span>
      </v-flex>
    </v-layout>
  </v-footer>
  <!-- footer : end -->
</template>

<script>
export default {
  name: 'GFooter'
}
</script>

<style scoped>
.mainContentsFooter >>> .moreNowrapTxt{    width: 200px !important; display: inline-block; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
.mainContentsFooter.v-footer {min-height: 64px;}
.mainContentsFooter {height:64px;line-height:64px;background-image: linear-gradient(to right, #19d5ad, #1c7ad8);}
.mainContentsFooter >>> .layout {height:64px;line-height:64px;font-size: 12px;font-weight: normal;font-style: normal;font-stretch: normal;letter-spacing: normal;text-align: center;color: #d1edf3;}
</style>
