<template>
  <!-- <v-text-field v-model="text" :type="type" :label="label" :error-messages="errors" :ref="refName" :outlined="outlined" :dense="dense" :color="color" @input.native="valueInput"></v-text-field> -->
  <v-text-field v-model="text" :type="type" :label="label" :value="value" :error-messages="errors" :hide-details="hideFlag" :class="[className, this.ellipsis ? 'ellipsis' : '']"
    :outlined="outlined" :dense="dense" :color="color" :counter="counter" :maxlength="maxlength" :clearable="clearable" :tabindex="tabindex" :readonly="readonly"
    placeholder=' ' :rounded="rounded" :single-line="singleLine"
    :prepend-icon="prependIcon" :append-icon="appendIcon" :prepend-inner-icon="prependInnerIcon" v-bind:style="{'width': width !== null ? width+'px' : ''}"
    @input.native="valueInput" @change="valueChange" @click:append="appendAction" @keyup.enter.native="keyupEnter" ref="GTextField">
    <template v-slot:prepend-inner>
      <slot name="prependInnerIcon"></slot>
    </template>
    <template v-slot:append-outer>
      <slot name="appendOuter"></slot>
    </template>
  </v-text-field>
  <!-- <v-tooltip v-model="errors.length" bottom :open-on-hover="false" :open-on-click="false"> -->
  <!-- <v-tooltip v-show="errors.length !== undefined && errors.length > 0" bottom :open-on-hover="false" :open-on-click="false">
    <template v-slot:activator="{ on }">
      <v-text-field v-on="on"
        v-model="text" :type="type" :label="label" :value="value" :error-messages="errors" :hide-details="hideDetail"
        :outlined="outlined" :dense="dense" :color="color" :counter="counter" :clearable="clearable" :tabindex="tabindex"
        @input.native="valueInput" @change="valueChange" ref="GTextField">
      </v-text-field>
      {{errorFlag}}@{{on}}@{{errors}}
    </template>
    <span>{{errors.length !== undefined && errors.length > 0 ? errors[0] : ''}}</span>
  </v-tooltip> -->
</template>

<script>

import Inko from 'inko'

export default {
  name: 'GTextField',
  props: {
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    label: {
      type: String,
      required: false
    },
    value: {
      required: false,
      default: ''
    },
    errors: {
      type: Array,
      required: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true
    },
    dense: {
      type: Boolean,
      required: false,
      default: true
    },
    color: {
      type: String,
      required: false,
      default: '#3ba8be'
    },
    counter: {
      type: Number,
      required: false
    },
    maxlength: {
      type: Number,
      required: false
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    tabindex: {
      type: Number,
      required: false
    },
    koRestricFlag: {
      type: Boolean,
      required: false
    },
    hideDetail: {
      type: Boolean,
      required: false,
      default: true
    },
    readonly: {
      type: Boolean,
      required: false
    },
    rounded: {
      type: Boolean,
      required: false
    },
    singleLine: {
      type: Boolean,
      required: false
    },
    prependIcon: {
      type: String,
      required: false
    },
    appendIcon: {
      type: String,
      required: false
    },
    prependInnerIcon: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: false
    },
    ellipsis: {
      type: Boolean,
      required: false
    },
    width: {
      type: Number,
      required: false,
      default: null
    }
  },
  data: () => ({
    text: '',
    errorFlag: false,
    hideFlag: false
  }),
  mounted () {
    this.text = this.value
    this.hideFlag = this.hideDetail
  },
  watch: {
    text (val) {
      // console.log('text', val)
      // if (this.koRestricFlag) {
      //   var inko = Inko()
      //   this.text = inko.inko.ko2en(val)
      // } else {
      //   this.text = val
      // }
      this.valueInput()
      // this.valueChange()
    },
    value (val) {
      // console.log('hideFlag', this.hideFlag)
      // console.log('value', val)
      // if (this.koRestricFlag) {
      //   var inko = Inko()
      //   console.log('value', inko.inko.ko2en(val))
      //   this.text = inko.inko.ko2en(val)
      // } else {
      //   this.text = val
      // }
      this.text = val
    },
    errors (val) {
      // console.log('errors', val.length)
      if (this.hideDetail) {
        this.hideFlag = !(val.length > 0)
      }
    }
  },
  methods: {
    valueInput: function () {
      if (this.text === null || this.text === '') {
        this.text = ''
      }
      this.$emit('input', this.text)
      // if (this.koRestricFlag) {
      //   var inko = Inko()
      //   // console.log('valueInput', inko.inko.ko2en(this.text))
      //   this.$emit('input', this.koRestricFlag ? inko.inko.ko2en(this.text) : this.text)
      // } else {
      //   this.$emit('input', this.text)
      // }
    },
    valueChange: function () {
      // console.log('change', this.text)
      if (this.koRestricFlag) {
        var inko = Inko()
        this.text = inko.inko.ko2en(this.text)
      }
      this.$emit('change', this.text)
    },
    focus: function () {
      this.$nextTick(() => this.$refs['GTextField'].focus())
    },
    tooltipClick: function (e) {
      // console.log('tooltipClick', e)
      e.preventDefault()
    },
    appendAction: function (e) {
      this.$emit('append')
    },
    keyupEnter: function () {
      this.$emit('keyup:enter')
    }
  }
}
</script>

<style scoped>
.v-text-field__details {margin-bottom: 0 !important;}
.ellipsis >>> .v-messages__message {text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
</style>
