import 'babel-polyfill'
import 'es6-promise/auto'
import Vue from 'vue'
// import './plugins/vuetify' // design components
import './plugins/axios' // backend 연동 모듈
import App from './App.vue'
import router from './router' // path control
import store from './vuex/store' // state manager
import { i18n } from './i18n' // 국제화
import MyPlugin from './plugins/myPlugin.js' // common plugin
import './vee-validate' // 유효성체크
// import ko from 'vee-validate/dist/locale/ko' // 유효성체크 한글
import Vuebar from 'vuebar' // scrollbar design
import Vue2TouchEvents from 'vue2-touch-events' // 터치 이벤트(swipe, tab)
import VueDragscroll from 'vue-dragscroll' // 스크롤 드래그
import VueCookies from 'vue-cookies'

import './assets/css/common.css' // common css
import 'vuetify/dist/vuetify.min.css'
import vuetify from './plugins/vuetify'

// import VueVideoPlayer from 'vue-video-player'
// import 'video.js/dist/video-js.css'
import Inko from 'inko'
// import VueClipboard from 'vue-clipboard2'

Vue.use(vuetify)
Vue.use(Vuebar)
Vue.use(Vue2TouchEvents)
Vue.use(MyPlugin)
Vue.use(VueDragscroll)
Vue.use(VueCookies)
// Vue.use(VueVideoPlayer)
Vue.use(Inko)
// VueClipboard.config.autoSetContainer = false
// Vue.use(VueClipboard)

const moment = require('moment')
require('moment/locale/ko')
Vue.use(require('vue-moment'), {
  moment
})

Vue.config.productionTip = false

Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB'
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB'
  }
  return size.toString() + ' B'
})

Vue.filter('comma', function (value) {
  return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
})

Vue.prototype.$EventBus = new Vue()
Vue.prototype.$filters = Vue.options.filters

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
