import Vue from 'vue'
import Router from 'vue-router'
import store from '@/vuex/store'
// import i18n from '@/i18n'
import Login from '@/views/Login.vue'
import LoginAdmin from '@/views/LoginAdmin.vue'
// import _ from 'lodash'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  // return originalPush.call(this, location).catch(err => err)
  return originalPush.call(this, location).catch(() => {
    // console.log('this', this, location)
    // console.log('event', event)
    // console.log('.menu1_item', $('.menu1_item'))
    // window.location.reload()
    // window.history.go(0)
  })
}

Vue.use(Router)

// 로그인 권한 체크
const requireAuth = (title, loginKind) => (to, from, next) => {
  store.state.detailFlag = false
  const accessInfo = sessionStorage.getItem('session_info')
  console.log('accessInfo', accessInfo)
  if (accessInfo !== undefined && accessInfo !== null && accessInfo !== '') {
    const loginInfo = JSON.parse(accessInfo)
    if (loginKind === 2) { // 현장관리자 메뉴 자체권한 체크
      if (loginInfo.loginKind === loginKind) {
        store.state.loginKey = loginInfo.loginKey
        store.state.userName = loginInfo.userName
        return next()
      }
    } else {
      store.state.loginKey = loginInfo.loginKey
      store.state.userName = loginInfo.userName
      return next()
    }
  }
  store.state.loginKey = ''
  store.state.userName = ''
  store.state.menus = ''
  next(store.state.loginAuthPath)
}

function loginView (pathStr) {
  const token = sessionStorage.getItem('session_info')
  if (token !== undefined && token !== null && token !== '') {
    if (pathStr === 'loginAdmin') {
      // store.state.loginAuthPath = '/loginAdmin'
      return () => import(/* webpackChunkName: "LoginAdmin" */ '@/views/LoginAdmin.vue')
    } else {
      // store.state.loginAuthPath = '/login'
      return () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue')
    }
  } else {
    if (pathStr === 'loginAdmin') {
      // store.state.loginAuthPath = '/loginAdmin'
      return LoginAdmin
    } else {
      // store.state.loginAuthPath = '/login'
      return Login
    }
  }
}

function redirectLoginPage () {
  return store.state.loginAuthPath
  // return '/login'
}

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.VUE_APP_BASE_URL,
  fallback: true,
  routes: [
    { path: '/login', name: 'Login', component: loginView('login') },
    { path: '/loginAdmin', name: 'LoginAdmin', component: loginView('loginAdmin') },
    {
      path: '/sub',
      name: 'Sub',
      // props: (route) => ({ query: route.query.pushUrl }),
      component: () => import(/* webpackChunkName: "Sub" */ '@/views/sub/Layout.vue'),
      beforeEnter: requireAuth(''),
      children: [
        /* 관리사무소 */
        { path: '/manager/apply', name: 'managerRequestList', component: () => import(/* webpackChunkName: "managerRequestList" */ '@/views/sub/manager/request/List.vue'), beforeEnter: requireAuth('managerRequestList') },
        { path: '/manager/user', name: 'managerUserList', component: () => import(/* webpackChunkName: "managerUserList" */ '@/views/sub/manager/user/List.vue'), beforeEnter: requireAuth('managerUserList') },
        { path: '/manager/house', name: 'managerLeaveInfoList', component: () => import(/* webpackChunkName: "managerLeaveInfoList" */ '@/views/sub/manager/leave/info/List.vue'), beforeEnter: requireAuth('managerLeaveInfoList') },
        { path: '/manager/withdrawUserLog', name: 'managerLeaveHistoryList', component: () => import(/* webpackChunkName: "managerLeaveHistoryList" */ '@/views/sub/manager/leave/history/List.vue'), beforeEnter: requireAuth('managerLeaveHistoryList') },
        /* 현장관리자 */
        { path: '/operation/siteManager', name: 'adminManageAccountList', component: () => import(/* webpackChunkName: "adminManageAccountList" */ '@/views/sub/admin/manage/account/List.vue'), beforeEnter: requireAuth('adminManageAccountList', 2) },
        { path: '/operation/user', name: 'adminManageUserList', component: () => import(/* webpackChunkName: "adminManageUserList" */ '@/views/sub/admin/manage/user/List.vue'), beforeEnter: requireAuth('adminManageUserList', 2) },
        { path: '/operation/tag', name: 'adminManageTagList', component: () => import(/* webpackChunkName: "adminManageTagList" */ '@/views/sub/admin/manage/tag/List.vue'), beforeEnter: requireAuth('adminManageTagList', 2) },
        { path: '/operation/mobileLog', name: 'adminManageMobileHistoryList', component: () => import(/* webpackChunkName: "adminManageMobileHistoryList" */ '@/views/sub/admin/manage/mobile/history/List.vue'), beforeEnter: requireAuth('adminManageMobileHistoryList', 2) },
        { path: '/operation/pushLog', name: 'adminManagePushHistoryList', component: () => import(/* webpackChunkName: "adminManagePushHistoryList" */ '@/views/sub/admin/manage/push/history/List.vue'), beforeEnter: requireAuth('adminManagePushHistoryList', 2) },
        { path: '/operation/complexLog', name: 'adminManageComplexHistoryList', component: () => import(/* webpackChunkName: "adminManageComplexHistoryList" */ '@/views/sub/admin/manage/complex/history/List.vue'), beforeEnter: requireAuth('adminManageComplexHistoryList', 2) },
        { path: '/mng/site', name: 'adminConfigSiteList', component: () => import(/* webpackChunkName: "adminConfigSiteList" */ '@/views/sub/admin/config/site/List.vue'), beforeEnter: requireAuth('adminConfigSiteList', 2) },
        { path: '/mng/ho', name: 'adminConfigDonghoList', component: () => import(/* webpackChunkName: "adminConfigDonghoList" */ '@/views/sub/admin/config/dongho/List.vue'), beforeEnter: requireAuth('adminConfigDonghoList', 2) },
        { path: '/mng/device', name: 'adminConfigEquipList', component: () => import(/* webpackChunkName: "adminConfigEquipList" */ '@/views/sub/admin/config/equip/List.vue'), beforeEnter: requireAuth('adminConfigEquipList', 2) },
        { path: '/mng/inout', name: 'adminConfigAccessList', component: () => import(/* webpackChunkName: "adminConfigAccessList" */ '@/views/sub/admin/config/access/List.vue'), beforeEnter: requireAuth('adminConfigAccessList', 2) },
        { path: '/mng/company', name: 'adminConfigCompanyList', component: () => import(/* webpackChunkName: "adminConfigCompanyList" */ '@/views/sub/admin/config/company/List.vue'), beforeEnter: requireAuth('adminConfigCompanyList', 2) },
        { path: '/system/manager/info', name: 'adminSystemManagerInfoList', component: () => import(/* webpackChunkName: "adminSystemManagerInfoList" */ '@/views/sub/admin/system/manager/info/List.vue'), beforeEnter: requireAuth('adminSystemManagerInfoList', 2) },
        { path: '/system/manager/group', name: 'adminSystemManagerGroupList', component: () => import(/* webpackChunkName: "adminSystemManagerGroupList" */ '@/views/sub/admin/system/manager/group/List.vue'), beforeEnter: requireAuth('adminSystemManagerGroupList', 2) },
        { path: '/system/menu/auth', name: 'adminSystemMenuAuthList', component: () => import(/* webpackChunkName: "adminSystemMenuAuthList" */ '@/views/sub/admin/system/menu/auth/List.vue'), beforeEnter: requireAuth('adminSystemMenuAuthList', 2) },
        /* common */
        { path: '/404', name: 'PageNotFound2', component: () => import(/* webpackChunkName: "PageNotFound" */ '@/views/sub/common/PageNotFound.vue'), beforeEnter: requireAuth('PageNotFound') },
        { path: '/error/404', name: 'PageNotFound', component: () => import(/* webpackChunkName: "PageNotFound" */ '@/views/sub/common/PageNotFound.vue'), beforeEnter: requireAuth('PageNotFound') }
      ]
    },
    // { path: '*', redirect: '/login' }
    { path: '*', redirect: redirectLoginPage() }
  ]
})
