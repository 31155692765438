<template>
  <v-container fluid fill-height class="loginBG pa-0">
    <v-layout justify-center :class="'align-center'">
      <div class="topBG hidden-md-and-up"></div>
      <div class="bottomBG hidden-md-and-up"></div>
      <v-flex>
        <ValidationObserver ref="observer" v-slot="{ invalid, passes }" tag="div" style="width:100%;">
          <v-form @submit.prevent="passes(loginSubmit)" id="loginForm">
            <v-card class="form"> <!--class="elevation-12"-->
              <v-card-title loginForm>
                  <div>
                    <span class="title_top">Site Adminstration System</span>
                    <span class="title_middle">{{$store.state.siteTitleName}}</span>
                    <span class="title_bottom"></span>
                  </div>
              </v-card-title>
              <v-card-text loginForm>
                <v-spacer></v-spacer>
                <v-row no-gutters style="min-height: 58px;">
                  <v-col>
                    <!-- <ValidationProvider name="user.id" rules="required|min:6|max:20" v-slot="{ errors }"> -->
                    <ValidationProvider name="user.id" rules="required|min:4|max:20" v-slot="{ errors }">
                      <g-text-field :label="$t('fields.user.id')" id="id" name="id" v-model="user.id" :value="user.id" :tabindex="1" :maxlength="20" :rounded="true" :singleLine="true" :errors="errors" ref="userId">
                        <template slot="prependInnerIcon"><v-icon class="mr-2">$vuetify.icons.user</v-icon></template>
                      </g-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row no-gutters style="min-height: 58px;">
                  <v-col>
                  <!-- <ValidationProvider name="user.pw" ref="user.password" rules="required|min:8|max:12|pass" v-slot="{ errors }"> -->
                    <ValidationProvider name="user.pw" ref="user.password" rules="required|min:4|max:12" v-slot="{ errors }">
                      <g-text-field :type="show1 ? 'text' : 'password'" :appendIcon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :label="$t('texts.passwordEng')" id="password" name="password" v-model="user.password" :value="user.password" :tabindex="2" :maxlength="12" :errors="errors" @append="show1 = !show1" :rounded="true" :singleLine="true" ref="userPassword">
                        <template slot="prependInnerIcon"><v-icon class="mr-2">$vuetify.icons.pw</v-icon></template>
                      </g-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row no-gutters style="min-height: 68px;">
                  <v-col style="margin-left: 16px;">
                    <v-checkbox v-model="idSaveFlag" class="mt-0 pt-0" :label="$t('texts.idSaveFlag')" :color="'#3ba8be'" dense style="font-size: 14px !important;"></v-checkbox>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider style="margin: 0 33px 0 33px;"></v-divider>
              <v-card-actions loginForm>
                <v-btn loginBtn type="submit" form="loginForm" style="height: 40px; font-size:20px !important;">
                  LOG IN&nbsp;
                  <img :src="images.arrow_r" class="svg_icon ml-1"/>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </ValidationObserver>
      </v-flex>
      <g-footer/>
    </v-layout>
  </v-container>
</template>

<script>
import gFooter from '@/components/GFooter'
import GTextField from '@/components/GTextField'

export default {
  name: 'LoginAdmin',
  components: {
    gFooter,
    GTextField
  },
  data () {
    return {
      user: {
        // id: 'tester',
        // password: 'rPdud#0401'
        // id: 'admin',
        // password: 'admin'
        id: '',
        password: ''
      },
      // sessionId: '',
      message: '',
      images: {
        dong: require('@/assets/images/login/icon-building.svg'),
        ho: require('@/assets/images/login/icon-home.svg'),
        name: require('@/assets/images/login/icon-user-1.svg'),
        pass: require('@/assets/images/login/icon-pw.svg'),
        reset: require('@/assets/images/sub/reset.svg'),
        arrow_r: require('@/assets/images/login/icon-arrow-r.svg')
      },
      show1: false,
      submitted: false,
      idSaveFlag: false,
      apply: {
        area: '',
        site: '',
        ip: ''

      },
      enroll: {
        area: '',
        site: ''
      }
    }
  },
  mounted () {
    // let self = this
    this.$store.state.currentMenuPath = ''
    this.$store.state.menuOverlay = true
    this.$store.state.loginKey = ''
    this.$store.state.userName = ''
    this.$store.state.menus = ''
    this.$store.state.profile = ''
    this.$store.state.mainMenuShow = true
    sessionStorage.removeItem('session_info')
    sessionStorage.removeItem('session_menuInfo')
    this.$store.state.loginSubtitle = process.env.VUE_APP_SITE_ADMIN_SUBTITLE_NAME
    this.$store.state.loginInfo.encKey = ''
    this.$store.state.loginAuthPath = '/loginAdmin'
    this.$store.state.mainInfo.loginKind = 2
    this.loginInfoSearch()
    // console.log('process.env.NODE_ENV', process.env.NODE_ENV)
    // console.log('process.env.VUE_APP_BASE_URI', process.env.VUE_APP_BASE_URI)
    // console.log('process.env.BASE_URL', process.env.BASE_URL)
  },
  methods: {
    async loginInfoSearch () {
      // this.$store.dispatch('loginInfoSearch', this.loginInfoSearchCallback)
      const loginInfo = this.$cookies.get('login_info')
      await setTimeout(function () {
        if (loginInfo !== null && loginInfo !== undefined && loginInfo.userId !== undefined && loginInfo.loginKind === 2) {
          this.idSaveFlag = loginInfo.idSaveFlag
          if (this.idSaveFlag) {
            this.user.id = loginInfo.userId
            this.$nextTick(() => this.$refs['userPassword'].focus())
          } else {
            this.$nextTick(() => this.$refs['userId'].focus())
          }
        } else {
          this.$nextTick(() => this.$refs['userId'].focus())
        }
      }.bind(this), 10)
    },
    loginSubmit (e) {
      this.submitted = true
      // this.$validator.validate().then(valid => {
      this.$refs.observer.validate().then(valid => {
        if (valid) {
          const encKey = this.$store.state.basicEncKey
          const key = window.getHex(encKey)
          const pass = window.getEncryptData(key, window.getHex(encKey), this.user.password)
          var param = {
            'id': this.user.id,
            'password': pass,
            'loginKind': 2
            // 'sessionId': this.sessionId
          }
          this.$store.dispatch('loginAdmin', [param, this.loginCallback])
        } else {
          let errorMsg = ''
          this.errors.items.forEach(element => {
            errorMsg += element.msg + '</br>'
          })
          if (errorMsg !== '') {
            this.$store.dispatch('snackbarShow', [errorMsg, null, false])
          }
        }
      })
    },
    loginCallback: function (response) {
      if (!window.formValidate.check(response.data)) {
        return
      }
      console.log('loginCallback', response.data)
      var success = response.data.success
      if (success) {
        if (this.idSaveFlag) {
          const user = { userId: this.user.id, idSaveFlag: this.idSaveFlag, loginKind: this.$store.state.mainInfo.loginKind }
          this.$cookies.set('login_info', user, 60 * 60 * 24 * 7, null, null, null, 'Lax') // 7day
        } else {
          this.$cookies.remove('login_info')
        }

        var loginKey = response.data.loginKey
        var encKey = response.data.encKey
        const id = response.data.id
        const userName = response.data.name
        const groupName = response.data.groupName
        const groupGrade = response.data.groupGrade
        var menus = response.data.menus
        // var menuTitle = response.data.menuTitle
        var mainSiteList = response.data.sites
        var selectedMainSiteSeq = null

        // this.$set(this.$store.state, 'menuTitle', menuTitle)
        this.$set(this.$store.state, 'menuTitle', this.$store.state.siteTitleName)
        this.$store.state.mainInfo.id = id
        this.$store.state.mainInfo.userName = userName
        this.$store.state.mainInfo.groupName = groupName
        this.$store.state.mainInfo.groupGrade = groupGrade
        this.$store.state.loginKey = loginKey
        this.$store.state.userName = response.data.userName
        this.$store.state.deliveryUseFlag = response.data.deliveryUseFlag
        this.$store.state.mainSiteList = mainSiteList
        if (mainSiteList !== null && mainSiteList !== undefined && mainSiteList.length > 0) {
          selectedMainSiteSeq = mainSiteList[0].siteSeq
        }
        console.log('login selectedMainSiteSeq', selectedMainSiteSeq)

        this.$store.commit('setLoginInfo', { loginKey: loginKey, encKey: encKey })

        // console.log('login menus', menus)
        this.$store.commit('setMenuInfo', [menus])
        console.log('login menus', menus)

        sessionStorage.setItem('session_info', JSON.stringify({
          loginKey: loginKey,
          id: id,
          userName: userName,
          groupName: groupName,
          groupGrade: groupGrade,
          loginKind: 2, // 현장관리
          mainSiteList: mainSiteList,
          selectedMainSiteSeq: selectedMainSiteSeq
          // menus: menus
          /*,
          xsrfHeaderName: xsrfHeaderName,
          xsrfHeaderValue: xsrfHeaderValue
          */
        }))
        // sessionStorage.setItem('session_second_menu', JSON.stringify([]))

        // menu init
        var headerMenu = JSON.parse(sessionStorage.getItem('session_menuInfo')).headerMenu
        console.log('login headerMenu', headerMenu)
        this.$store.state.currentMenuSeq = headerMenu[0].menuSeq
        console.log('login currentMenuSeq', headerMenu[0].menuSeq)
        this.$store.dispatch('setLeftMenu', [false, headerMenu[0].menuSeq])
        this.setCurrentMenu()

        this.$store.dispatch('snackbarClose')
        const routeMenu = headerMenu[0].children.length > 0 ? headerMenu[0].children[0] : headerMenu[0]
        console.log('login routeMenu', routeMenu)
        const routeMenuPath = routeMenu.menuPath !== undefined && routeMenu.menuPath !== null && routeMenu.menuPath !== '' ? routeMenu.menuPath : '/operation/siteManager'
        console.log('routeMenuPath', routeMenuPath)
        this.$store.commit('setCurrentMenu1Title', headerMenu[0].menuName)
        this.$router.push({ path: routeMenuPath })
      } else {
        let msg = this.$t('texts.login.fail')
        this.$store.dispatch('snackbarShow', [msg, response.data, success])
      }
    },
    setCurrentMenu: function () {
      // console.log('login currentMenu', this.$store.state.menus.secondMenu)
      if (this.$store.state.menus !== undefined && this.$store.state.menus.secondMenu !== undefined) {
        let currentMenu = this.$store.state.menus.secondMenu.filter(item => Number(item.menuSeq) === Number(this.$store.state.currentMenuSeq))
        if (currentMenu.length === 0) {
          let flag = this.$store.state.menus.secondMenu.some(depth2 => {
            let childFlag = depth2.children.some((depth3) => {
              if (Number(depth3.menuSeq) === Number(this.$store.state.currentMenuSeq)) {
                this.$store.state.currentMenu3Title = depth3.menuName
              }
              return Number(depth3.menuSeq) === Number(this.$store.state.currentMenuSeq)
            })
            if (childFlag) {
              this.$store.state.currentMenu2Title = depth2.menuName
            }
            return childFlag
          })
          if (!flag) {
            this.$store.state.currentMenu2Title = this.$store.state.menus.secondMenu[0].menuName
            if (this.$store.state.menus.secondMenu[0].children.length > 0) {
              this.$store.state.currentMenu3Title = this.$store.state.menus.secondMenu[0].children[0].menuName
            } else {
              this.$store.state.currentMenu3Title = null
            }
          }
        } else {
          this.$store.state.currentMenu2Title = currentMenu[0].menuName
          this.$store.state.currentMenu3Title = null
        }
      }
    },
    clearMessage: function (target) {
      if (target === 'id') this.user.id = ''
    },
    checkMessage: function (msg, flag) {
      this.$store.dispatch('snackbarShow', [msg, null, flag])
    }
  }
}
</script>
<style scoped>
.loginBG {background-image: url('~@/assets/images/login/bg-login.png');background-position: center 30%;background-color: #f5f7fa;min-height:600px;}
.container.fluid {padding: 0;padding-bottom: 64px;}
.flexs {position: absolute;}
.flex {margin: 0 auto;flex-grow: 100}
.container.fill-height > .layout {background-image: linear-gradient(to right, #19d5ad, #1c7ad8);background-size: 100% 8px;}
.elevation-12 {width: 384px;/*height:499px;*/border-radius: 20px;box-shadow: 8px 25px 36px 0 rgba(0, 0, 0, 0.2);background-color: #ffffff;margin: 0 auto;}
.form {width: 384px;/*height:499px;*/border-radius: 20px;box-shadow: 8px 25px 36px 0 rgba(0, 0, 0, 0.2);background-color: #ffffff;margin: 0 auto;}
.v-card__title[loginForm] {height: 175px;}
.v-card__title[loginForm] div {display: block;width: 100%;margin-top: 40px; text-align: center;}
.v-card__title[loginForm] span {display: block;}
.title_top {font-size: 16px;color : #1c7ad8;height: 24px; line-height:36px; letter-spacing: -0.5px;font-weight: 500;}
.title_middle {font-size: 36px; line-height:57px; height: 57px;letter-spacing: -2px;color: #444444;font-weight: 500;}
.title_bottom {font-size: 14px;height: 17px; line-height:17px; color: #888888;font-weight: normal; margin-bottom: 24px;}
.v-card__text[loginForm] {padding: 0px 33px 0 33px;}
.v-card__text[loginForm] .spacer {height: 24px;margin-bottom: 36px;background: url('~@/assets/images/login/divider-login-diagonal-pattern.svg') repeat-x;}
.v-sheet.v-card.form{border-radius: 20px;}
.v-card__actions[loginForm] > .v-btn.theme--light {font-size: 20px; font-weight: normal; color: #ffffff;}
.v-card__actions[loginForm] {padding: 12px 32px 24px 32px;}
.v-btn[loginBtn] {width: 100%;height: 40px;object-fit: contain;border-radius: 25px; font-size: 1rem !important; background-image: linear-gradient(to right, #3ed6d7, #1c7ad8);}
.v-input .v-label {top: 10px;}
/* .v-card__text >>> .v-input.v-text-field.theme--light .v-input__slot {margin-bottom: 0;}
.v-card__text >>> .v-input.v-text-field.theme--light .v-text-field__details {display: none;}
.v-card__text >>> .v-input.v-text-field.theme--light.error--text .v-input__slot {margin-bottom: 0px;}
.v-card__text >>> .v-input.v-text-field.theme--light.error--text .v-text-field__details {display: block;}
.v-card__text >>> .v-input.v-text-field.theme--light.error--text .v-messages__message {padding-left: 18px;}
.v-text-field.v-text-field:not(.v-text-field-flat) >>> .v-input__control > .v-input__slot {border-radius: 25px;border: solid 1px #cccccc;padding-left: 16px;padding-right: 16px;}
.v-text-field.v-text-field:not(.v-text-field-flat).error--text >>> .v-input__control > .v-input__slot {border-color: #EF9A9A;}
.v-text-field.v-text-field:not(.v-text-field-flat).success--text >>> .v-input__control > .v-input__slot {border-color: #81C784;}
.v-text-field.v-text-field >>> .v-input__control {min-height: 40px; margin-top: 8px;}
.v-text-field.v-text-field >>> .v-input__prepend-inner {margin-right: 8px;margin-top: 6px;min-width: 24px;height: 24px;padding: 0;}
.v-text-field.v-text-field >>> .v-input__append-inner {margin-top: 7px;min-width: 24px;height: 24px;padding: 0;}
.v-text-field.v-text-field >>> .theme--light.v-icon {color: #cccccc;}
.v-text-field.v-text-field >>> .theme--light.v-icon.error--text {color: #cccccc !important;}
.v-text-field.v-text-field >>> .theme--light.v-icon.success--text {color: #cccccc !important;}
.v-text-field.v-text-field >>> .theme--light.v-icon.primary--text {color: #cccccc !important;}
.v-text-field.v-text-field >>> .v-input__control > .v-input__slot::before {border: none;}
.v-text-field.v-text-field >>> .v-input__control > .v-input__slot::after {border: none;} */
/* .v-text-field.v-text-field >>> .v-input__control > .v-input__slot input::placeholder {font-family:'NotoSansCJKKR';} */
.v-text-field.v-text-field {margin-top: 0;padding-top: 0;}
.error--text >>> .v-text-field__slot input::placeholder { color: #EF9A9A !important;}
.v-input.theme--light {caret-color: #000000 !important;}

.checkbox_area {height: 32px;padding: 0; border-bottom: 1px solid #dddddd;}
.checkbox_area >>> label {display: none;height: 24px;line-height: 24px;clear: both;}
.checkbox_area >>> img {height: 24px;width: 24px; display: block;float: left;}
.checkbox_area >>> span {display: block;height: 24px;float: left; padding-left: 4px;font-size: 14px;color: #8c8c8c;}

.v-input >>> input[type=number]::-webkit-outer-spin-button,.v-input >>> input[type=number]::-webkit-inner-spin-button {-webkit-appearance: none !important;appearance: none !important;-moz-appearance: none !important;margin: 0;opacity: 0;opacity: 0;}

.mainContentsFooter.v-footer {position: fixed;bottom:0;left:0;width:100%;}

.bubble {
  z-index:100;position: relative;height: auto;padding: 10px 10px 10px 10px;
  background: #FFFFFF;border-radius: 5px;
  position: absolute;font-size: 16px;text-align: left;}
.bubble:after{
  content: '';position: absolute;border-style: solid;border-width: 20px 14px 0 14px;
  border-color: #FFFFFF transparent;display: block;width: 0;z-index: 1;bottom: -15px;left: 180px;
}

/* mobile screen */
/* @media (max-width: 960px) {
  .loginBG {background-image: none;background-position: center center;background-color: #fff;min-height: unset;}
  .flex {position: relative;}
  .container.fill-height > .layout {background: none;}
  .v-footer {display: none;}
  .container.fluid {padding-bottom: 0;}
  .elevation-12 {width: 100%;height: 100%; border-radius: 0px;box-shadow: none !important;background-color: transparent;margin: 0 auto;}
  .form {width: 100%;height: 100%; border-radius: 0px;box-shadow: none !important;background-color: transparent;margin: 0 auto;}
  .v-card__title {padding: 100px 32px 24px 32px;height: 205.5px;}
  .v-card__title div {text-align: left;margin-top: 0px;}
  .title_top {color : #289bd8;font-weight: normal;}
  .title_middle {font-size: 28px;height: 41px;letter-spacing: -1.56px;color: #444444;font-weight: normal;}
  .title_bottom {font-size: 14px;height: 16px;}
  .v-card__actions {padding-top: 24px; }
  .topBG {position: absolute;width: 100%;height: 100%;background: url('~@/assets/images/login/bg-login-mobile-top.svg') top center no-repeat;display: block;top: 0; left:0;}
  .bottomBG {position: absolute;width: 100%;height: 100%;background: url('~@/assets/images/login/bg-login-mobile-bottom.svg') bottom center no-repeat;display: block;top: 0; left:0;}
  .checkbox_area {height: 72px;padding: 24px 0 24px 0; }
  .checkbox_area >>> label {display: inline-block;}
} */
.iconArea{width: 248px; height: 128px; display: inline-block;}
.iconArea > .icon{margin: auto; top: 40px;}

</style>
