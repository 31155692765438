import Vue from 'vue'
import { i18n } from '../i18n'
import _ from 'lodash'

const bundleTimeout = 1800 * 1000
const backendAdminReqName = 'rtlsmng'
const backendManagerReqName = 'rtlsapt'

export default {
  // 에러 발생 시 다이얼로그 띄우기
  showErrorDialog: function ({ state }, payload) {
    state.dialogErrorTitle = payload.title
    state.dialogErrorMsg = payload.message
    state.dialogErrorFlag = true
    if (payload.autoClose === undefined || payload.autoClose) {
      var timer = setTimeout(function () {
        if (state.dialogErrorFlag) {
          state.dialogErrorFlag = false
          state.dialogTimer = null
          clearTimeout(timer)
        }
      }, 5000)
      state.dialogTimer = timer
    }
  },
  // 에러 다이얼로그 닫기
  closeErrorDialog: function ({ state }) {
    state.dialogErrorFlag = false
    var timer = state.dialogTimer
    if (timer !== null) {
      state.dialogTimer = null
      clearTimeout(timer)
    }
  },
  snackbarShow: function ({ state }, [msg, data, success]) { // snackbar display (action success, fail)
    // snackbar close
    state.snackbarFlag = false
    state.snackbarMsg = ''
    state.snackbarColor = ''

    state.snackbarTop = true
    state.snackbarBottom = false
    state.snackbarRight = false
    state.snackbarVertical = false
    state.snackbarAutoHeight = true

    setTimeout(function () {
      // snackbar show
      // state.snackbarMsg = msg
      // state.snackbarMsg = msg + (cause !== null ? ' (' + cause + ')' : '')
      state.snackbarMsg = data !== null && data !== undefined && data.errorCode !== undefined ? data.errorArgs !== undefined ? i18n.t('error.' + data.errorCode, data.errorArgs) : i18n.t('error.' + data.errorCode) : msg
      if (success) {
        state.snackbarColor = 'success'
      } else {
        state.snackbarColor = 'error'
      }
      /*
      if (state.windowWidth >= 960) {
        state.snackbarTop = false
        state.snackbarBottom = true
        state.snackbarRight = false
        state.snackbarVertical = false
        state.snackbarAutoHeight = false
      } else {
        state.snackbarTop = true
        state.snackbarBottom = false
        state.snackbarRight = true
        state.snackbarVertical = true
        state.snackbarAutoHeight = true
      }
      */
      state.snackbarFlag = true
    }, 100)
  },
  snackbarClose: function ({ state }) { // snackbar close
    state.snackbarFlag = false
    state.snackbarMsg = ''
    state.snackbarColor = ''
    /*
    state.snackbarTop = true
    state.snackbarBottom = false
    state.snackbarRight = true
    state.snackbarVertical = true
    state.snackbarAutoHeight = true
    */
  },
  contentsDragFalseChange: function ({ state }) { // contents drag false
    setTimeout(function () {
      state.contentsDragFlag = false
    }, 300)
  },
  login: function (_context, [param, callback]) { // 관리사무소 로그인
    const axiosOption = { method: 'POST', url: '/' + backendManagerReqName + '/v1/login', data: param }
    Vue.axios(axiosOption).then(callback)
  },
  loginAdmin: function (_context, [param, callback]) { // 현장관리자 로그인
    const axiosOption = { method: 'POST', url: '/' + backendAdminReqName + '/v1/login', data: param }
    Vue.axios(axiosOption).then(callback)
  },
  setLeftMenu: function (store, [iconFlag, payload]) {
    let tmpSecondMenu = []
    let settingFlag = _.some(JSON.parse(sessionStorage.getItem('session_menuInfo')).settingMenu, depth1 => depth1.menuSeq === payload)
    let headerType = iconFlag ? settingFlag ? 'settingMenu' : 'fireAlarmMenu' : 'headerMenu'
    let menuList = JSON.parse(sessionStorage.getItem('session_menuInfo'))[headerType]
    menuList.forEach(menu => {
      if (menu.children.length > 0) {
        let childMenu = menu.children
        for (var idx in childMenu) {
          if (childMenu[idx].parentSeq === payload) {
            tmpSecondMenu.push(childMenu[idx])
          }
        }
      }
    })
    store.commit('setSecondMenuList', tmpSecondMenu)
  },
  logout: function (_context, callback) { // 로그아웃
    const response = {
      'data': {
        'success': true
      }
    }
    setTimeout(function () { callback.call(window, response) }, 300)
    // const axiosOption = { method: 'POST', url: '/' + frontSiteName + '/v1/logout' }
    // Vue.axios(axiosOption).then(callback)
  },
  // house dong
  houseDongList: function (store, [callback]) { // 동 조회
    var param = '?searchSiteSeq=' + store.state.mainInfo.selectedMainSiteSeq
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendManagerReqName + '/v1/com/dong' + param }
    Vue.axios(axiosOption).then(callback)
  },
  // house ho
  houseHoList: function (store, [dongCode, callback]) { // 호 조회
    console.log('[param] dongCode: ' + dongCode)
    var param = '?searchSiteSeq=' + store.state.mainInfo.selectedMainSiteSeq + '&searchDongCode=' + dongCode
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendManagerReqName + '/v1/com/ho' + param }
    Vue.axios(axiosOption).then(callback)
  },
  // system manager group
  managerGroupList: function (_context, [managerGroupName, pageNum, rowCount, callback]) { // 관리자그룹 조회
    console.log('[param] managerGroupName: ' + managerGroupName + ', pageNum: ' + pageNum + ', rowCount: ' + rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchManagerGroupName=' + managerGroupName
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/system/manager/group' + param }
    Vue.axios(axiosOption).then(callback)
  },
  managerGroupDetail: function (_context, [managerGroupSeq, callback]) { // 관리자그룹 상세 조회
    const reqManagerGroupSeq = managerGroupSeq
    console.log('managerGroupDetail', reqManagerGroupSeq)
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/system/manager/group/' + reqManagerGroupSeq }
    Vue.axios(axiosOption).then(callback)
  },
  managerGroupInsert: function (_context, [data, callback]) { // 관리자그룹 정보 등록
    console.log('managerGroupInsert', data)
    const axiosOption = { method: 'POST', url: '/' + backendAdminReqName + '/v1/system/manager/group', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  managerGroupUpdate: function (_context, [data, callback]) { // 관리자그룹 정보 수정
    const reqManagerGroupSeq = data.managerGroupSeq
    console.log('managerGroupUpdate', reqManagerGroupSeq)
    const axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + '/v1/system/manager/group/' + reqManagerGroupSeq, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  managerGroupDelete: function (_context, [data, callback]) { // 관리자그룹 정보 삭제
    const reqManagerGroupSeq = data.managerGroupSeq
    console.log('managerGroupDelete', reqManagerGroupSeq)
    const axiosOption = { method: 'DELETE', url: '/' + backendAdminReqName + '/v1/system/manager/group/' + reqManagerGroupSeq }
    Vue.axios(axiosOption).then(callback)
  },
  // system manager info
  managerInfoList: function (_context, [managerId, managerName, managerGroupSeq, pageNum, rowCount, callback]) { // 관리자계정 조회
    console.log('[param] managerId: ' + managerId + ', managerName: ' + managerName + ', managerGroupSeq: ' + managerGroupSeq + ', pageNum: ' + pageNum + ', rowCount: ' + rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchManagerId=' + managerId + '&searchManagerName=' + managerName + '&searchManagerGroupSeq=' + managerGroupSeq
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/system/manager/info' + param }
    Vue.axios(axiosOption).then(callback)
  },
  managerInfoDetail: function (_context, [managerSeq, callback]) { // 관리자계정 상세 조회
    const reqManagerSeq = managerSeq
    console.log('managerInfoDetail', reqManagerSeq)
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/system/manager/info/' + reqManagerSeq }
    Vue.axios(axiosOption).then(callback)
  },
  managerInfoInsert: function (_context, [data, callback]) { // 관리자계정 정보 등록
    console.log('managerInfoInsert', data)
    const axiosOption = { method: 'POST', url: '/' + backendAdminReqName + '/v1/system/manager/info', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  managerInfoUpdate: function (_context, [data, callback]) { // 관리자계정 정보 수정
    const reqManagerSeq = data.managerSeq
    console.log('managerInfoUpdate', reqManagerSeq)
    const axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + '/v1/system/manager/info/' + reqManagerSeq, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  managerInfoDelete: function (_context, [data, callback]) { // 관리자계정 정보 삭제
    const reqManagerSeq = data.managerSeq
    console.log('managerInfoDelete', reqManagerSeq)
    const axiosOption = { method: 'DELETE', url: '/' + backendAdminReqName + '/v1/system/manager/info/' + reqManagerSeq }
    Vue.axios(axiosOption).then(callback)
  },
  // menu auth
  menuList: function (_context, callback) {
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/system/menu/auth' }
    Vue.axios(axiosOption).then(callback)
  },
  menuAuthList: function (_context, [groupSeq, callback]) {
    const seq = groupSeq
    console.log('menuAuthList', seq)
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + `/v1/system/menu/auth/${seq}` }
    Vue.axios(axiosOption).then(callback)
  },
  menuAuthUpdate: function (_context, [data, callback]) {
    const groupSeq = data.groupSeq
    console.log('menuAuthUpdate', groupSeq)
    const axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + `/v1/system/menu/auth/${groupSeq}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  // user pw update
  userPwUpdate: function (store, [managerId, managerPw, managerPwConfirm, callback]) { // 사용자 비밀번호 변경
    console.log('userPwUpdate', managerId, managerPw, managerPwConfirm)
    let data = { 'managerId': managerId, 'managerPw': managerPw, 'managerPwConfirm': managerPwConfirm }
    var axiosOption = null
    if (store.state.mainInfo.loginKind === 1) {
      axiosOption = { method: 'PATCH', url: '/' + backendManagerReqName + '/v1/main/user/pw', data: data }
    } else {
      axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + '/v1/main/user/pw', data: data }
    }
    Vue.axios(axiosOption).then(callback)
  },
  //
  //
  //
  vueRouterChange: function (_context, vuePath) {
    try {
      if (/Android/i.test(navigator.userAgent)) {
        window.android.vueChange(vuePath)
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        var message = { 'vuePath': vuePath }
        window.webkit.messageHandlers.vueChange.postMessage(JSON.stringify(message))
      }
    } catch (e) {
      console.log('app call fail...')
    }
  },
  dialogFormInit: function (store) { // 메인화면 사용자정보 확인 팝업 초기화
    store.state.userInfoDialog = false
    store.state.userUpdateFlag = false
  },
  // TODO: 신규
  // 관리사무소 - 신청자
  managerRequestList: function (store, [dongCode, hoCode, userAlias, pageNum, rowCount, callback]) { // 관리자-신청자 조회
    console.log('managerRequestList', dongCode, hoCode, userAlias, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchSiteSeq=' + store.state.mainInfo.selectedMainSiteSeq
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendManagerReqName + '/v1/manager/apply' + param }
    Vue.axios(axiosOption).then(callback)
  },
  managerRequestAgree: function (_context, [data, callback]) { // 관리자-신청자 승인
    const axiosOption = { method: 'POST', url: '/' + backendManagerReqName + '/v1/manager/apply/allowlist', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  managerRequestDelete: function (_context, [data, callback]) { // 관리자-신청자 삭제
    const axiosOption = { method: 'POST', url: '/' + backendManagerReqName + '/v1/manager/apply/deletelist', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  // 관리사무소 - 사용자
  managerUserList: function (store, [dongCode, hoCode, userAlias, mobileId, authState, pageNum, rowCount, callback]) { // 관리자-사용자 조회
    console.log('managerUserList', dongCode, hoCode, userAlias, mobileId, authState, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchSiteSeq=' + store.state.mainInfo.selectedMainSiteSeq
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendManagerReqName + '/v1/manager/user' + param }
    Vue.axios(axiosOption).then(callback)
  },
  managerUserLeave: function (_context, [data, callback]) { // 관리자-사용자 탈퇴
    const axiosOption = { method: 'POST', url: '/' + backendManagerReqName + '/v1/manager/user/deletelist', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  // 관리사무소 - 세대탈퇴
  managerLeaveInfoList: function (store, [dongCode, hoCode, pageNum, rowCount, callback]) { // 관리자-세대목록 조회
    console.log('managerLeaveInfoList', dongCode, hoCode, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchSiteSeq=' + store.state.mainInfo.selectedMainSiteSeq
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendManagerReqName + '/v1/manager/house' + param }
    Vue.axios(axiosOption).then(callback)
  },
  managerLeaveInfoDetail: function (_context, [siteSeq, dongCode, hoCode, callback]) { // 관리자-세대목록 상세
    const axiosOption = { method: 'GET', url: '/' + backendManagerReqName + '/v1/manager/house/' + siteSeq + '/' + dongCode + '/' + hoCode }
    Vue.axios(axiosOption).then(callback)
  },
  managerLeaveInfoHouseLeave: function (_context, [data, callback]) { // 관리자-세대 탈퇴
    console.log('managerLeaveInfoHouseLeave', data)
    const axiosOption = { method: 'DELETE', url: '/' + backendManagerReqName + '/v1/manager/house', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  // 관리사무소 -세대탈퇴 이력
  managerLeaveHistoryList: function (store, [dongCode, hoCode, startDate, endDate, pageNum, rowCount, callback]) { // 카드 발급/수정/삭제 이력 조회
    console.log('managerLeaveInmanagerLeaveHistoryListfoList', dongCode, hoCode, startDate, endDate, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchStartDate=' + startDate + '&searchEndDate=' + endDate + '&searchSiteSeq=' + store.state.mainInfo.selectedMainSiteSeq
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendManagerReqName + '/v1/manager/withdrawUserLog' + param }
    Vue.axios(axiosOption).then(callback)
  },
  // 현장관리 - 관리 - 현장계정
  adminManageAccountList: function (store, [siteManagerId, siteManagerName, siteSeq, siteName, pageNum, rowCount, callback]) { // 현장계정 조회
    console.log('adminManageAccountList', siteManagerId, siteManagerName, siteSeq, siteName, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchSiteManagerId=' + siteManagerId + '&searchSiteManagerName=' + siteManagerName + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/operation/siteManager' + param }
    Vue.axios(axiosOption).then(callback)
  },
  adminManageAccountDetail: function (store, [siteManagerSeq, callback]) { // 현장계정 상세
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/operation/siteManager/' + siteManagerSeq }
    Vue.axios(axiosOption).then(callback)
  },
  adminManageAccountInsert: function (store, [data, callback]) { // 현장계정 등록
    console.log('adminManageAccountInsert', data)
    const axiosOption = { method: 'POST', url: '/' + backendAdminReqName + '/v1/operation/siteManager', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminManageAccountUpdate: function (_context, [data, callback]) { // 현장계정 수정
    console.log('adminManageAccountUpdate', data)
    const axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + `/v1/operation/siteManager/${data.siteManagerSeq}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminManageAccountDelete: function (_context, [data, callback]) { // 현장계정 삭제
    console.log('adminManageAccountDelete', data)
    const axiosOption = { method: 'DELETE', url: '/' + backendAdminReqName + `/v1/operation/siteManager/${data.siteManagerSeq}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminManageAccountPwInit: function (_context, [data, callback]) { // 현장계정 비밀번호 초기화
    console.log('adminManageAccountPwInit', data)
    const axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + `/v1/operation/siteManager/resetPw/${data.siteManagerSeq}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  // 현장관리 - 사용자
  adminManageUserList: function (_context, [siteSeq, siteName, dongCode, hoCode, userAlias, mobileId, authState, pageNum, rowCount, callback]) { // 현장관리-사용자 조회
    console.log('adminManageUserList', siteSeq, siteName, dongCode, hoCode, userAlias, mobileId, authState, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/operation/user' + param }
    Vue.axios(axiosOption).then(callback)
  },
  adminManageUserLeave: function (_context, [data, callback]) { // 현장관리-사용자 탈퇴
    console.log('adminManageUserLeave', data)
    const axiosOption = { method: 'DELETE', url: '/' + backendAdminReqName + `/v1/operation/user/${data.userSeq}` }
    Vue.axios(axiosOption).then(callback)
  },
  adminManageUserListLeave: function (_context, [data, callback]) { // 현장관리-사용자 리스트 탈퇴
    console.log('adminManageUserListLeave', data)
    const axiosOption = { method: 'POST', url: '/' + backendAdminReqName + `/v1/operation/user/deletelist`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  // 현장관리 - 태그
  adminManageTagList: function (_context, [siteSeq, siteName, dongCode, hoCode, serialId, tagType, pageNum, rowCount, callback]) { // 현장관리-사용자 조회
    console.log('adminManageUserList', siteSeq, siteName, dongCode, hoCode, serialId, tagType, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchSerialId=' + serialId
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/operation/tag' + param }
    Vue.axios(axiosOption).then(callback)
  },
  // 현장관리 - 모바일연동이력
  adminManageMobileHistoryList: function (_context, [startDate, endDate, siteSeq, siteName, dongCode, hoCode, userAlias, logType, result, url, reqData, rspData, pageNum, rowCount, callback]) { // 현장관리-모바일연동이력 조회
    console.log('adminManageMobileHistoryList', startDate, endDate, siteSeq, siteName, dongCode, hoCode, userAlias, logType, result, url, reqData, rspData, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchStartDate=' + startDate + '&searchEndDate=' + endDate + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchUserAlias=' + userAlias + '&searchLogType=' + logType + '&searchResult=' + result + '&searchUrl=' + url + '&searchReqData=' + reqData + '&searchRspData=' + rspData
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/operation/mobileLog' + param,
      timeout: 100 * 1000
    }
    Vue.axios(axiosOption).then(callback)
  },
  adminManageMobileHistoryExcelDown: function (_context, [startDate, endDate, siteSeq, siteName, dongCode, hoCode, userAlias, logType, result, url, reqData, rspData, callback]) { // 현장관리-모바일연동이력 엑셀다운
    var param = '?searchStartDate=' + startDate + '&searchEndDate=' + endDate + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchUserAlias=' + userAlias + '&searchLogType=' + logType + '&searchResult=' + result + '&searchUrl=' + url + '&searchReqData=' + reqData + '&searchRspData=' + rspData
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/operation/mobileLog/bundle' + param,
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 현장관리 - PUSH연동이력
  adminManagePushHistoryList: function (_context, [startDate, endDate, siteSeq, siteName, dongCode, hoCode, userAlias, result, pageNum, rowCount, callback]) { // 현장관리-모바일연동이력 조회
    console.log('adminManagePushHistoryList', startDate, endDate, siteSeq, siteName, dongCode, hoCode, userAlias, result, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchStartDate=' + startDate + '&searchEndDate=' + endDate + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchUserAlias=' + userAlias + '&searchResultFlag=' + result
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/operation/pushLog' + param }
    Vue.axios(axiosOption).then(callback)
  },
  adminManagePushHistoryExcelDown: function (_context, [startDate, endDate, siteSeq, siteName, dongCode, hoCode, userAlias, result, callback]) { // 현장관리-모바일연동이력 엑셀다운
    var param = '?searchStartDate=' + startDate + '&searchEndDate=' + endDate + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchUserAlias=' + userAlias + '&searchResultFlag=' + result
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/operation/pushLog/bundle' + param,
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 현장관리 - 단지서버 연동이력
  adminManageComplexHistoryList: function (_context, [startDate, endDate, siteSeq, siteName, dongCode, hoCode, ip, logType, result, pageNum, rowCount, callback]) { // 현장관리-모바일연동이력 조회
    console.log('adminManageComplexHistoryList', startDate, endDate, siteSeq, siteName, dongCode, hoCode, ip, logType, result, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchStartDate=' + startDate + '&searchEndDate=' + endDate + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchIp=' + ip + '&searchLogType=' + logType + '&searchResult=' + result
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/operation/complexLog' + param }
    Vue.axios(axiosOption).then(callback)
  },
  adminManageComplexHistoryExcelDown: function (_context, [startDate, endDate, siteSeq, siteName, dongCode, hoCode, ip, logType, result, callback]) { // 현장관리-모바일연동이력 엑셀다운
    var param = '?searchStartDate=' + startDate + '&searchEndDate=' + endDate + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode + '&searchIp=' + ip + '&searchLogType=' + logType + '&searchResult=' + result
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/operation/complexLog/bundle' + param,
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },

  // 현장관리 - 설정 - 현장
  adminConfigSiteList: function (store, [companySeq, siteSeq, siteName, siteType, adminFlag, pageNum, rowCount, callback]) { // 현장 조회
    console.log('adminConfigSiteList', companySeq, siteSeq, siteName, siteType, adminFlag, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchSiteType=' + siteType + '&searchCompanySeq=' + companySeq
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/mng/site' + param }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigSiteDetail: function (store, [siteSeq, callback]) { // 현장 상세
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/mng/site/' + siteSeq }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigSiteInsert: function (_context, [data, callback]) { // 현장 등록
    console.log('adminConfigSiteInsert', data)
    const axiosOption = { method: 'POST', url: '/' + backendAdminReqName + '/v1/mng/site', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigSiteUpdate: function (_context, [data, callback]) { // 현장 수정
    console.log('adminConfigSiteUpdate', data)
    const axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + `/v1/mng/site/${data.siteSeq}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigSiteDelete: function (_context, [data, callback]) { // 현장 삭제
    console.log('adminConfigSiteDelete', data)
    const axiosOption = { method: 'DELETE', url: '/' + backendAdminReqName + `/v1/mng/site/${data.siteSeq}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigSiteExcelDown: function (_context, [companySeq, siteSeq, siteName, siteType, adminFlag, callback]) { // 현장리스트 엑셀다운
    var param = '?searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchSiteType=' + siteType + '&searchCompanySeq=' + companySeq
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/mng/site/bundle' + param,
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigSiteBundleInsert: function (_context, [file, callback]) { // 현장 일괄등록
    const fileData = file
    console.log('adminConfigSiteBundleInsert', fileData)
    if (file !== undefined && file !== null && file !== '' && file.size !== undefined && file.size > 0) {
      const axiosOption = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: bundleTimeout
      }
      const data = new FormData()
      data.append('file', fileData)
      Vue.axios.post('/' + backendAdminReqName + '/v1/mng/site/bundle', data, axiosOption).then(callback)
    }
  },
  adminConfigSiteInsertSampleExcelDown: function (_context, callback) { // 현장 일괄등록 양식 엑셀다운
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/mng/site/bundle/sample',
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 현장관리 - 설정 - 동호
  adminConfigDonghoList: function (store, [siteSeq, siteName, dongCode, hoCode, pageNum, rowCount, callback]) { // 현장설치 - 동호 조회
    console.log('adminConfigSiteList', siteSeq, siteName, dongCode, hoCode, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/mng/ho' + param }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigDonghoDetail: function (store, [siteSeq, dongCode, hoCode, callback]) { // 동호 상세
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/mng/ho/' + siteSeq + '/' + dongCode + '/' + hoCode }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigDonghoInsert: function (_context, [data, callback]) { // 동호 등록
    console.log('adminConfigDonghoInsert', data)
    const axiosOption = { method: 'POST', url: '/' + backendAdminReqName + '/v1/mng/ho', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigDonghoUpdate: function (_context, [data, callback]) { // 동호 수정
    console.log('adminConfigDonghoUpdate', data)
    const axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + `/v1/mng/ho/${data.siteSeq}/${data.dongCode}/${data.hoCode}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigDonghoDelete: function (_context, [data, callback]) { // 동호 삭제
    console.log('adminConfigDonghoDelete', data)
    const axiosOption = { method: 'DELETE', url: '/' + backendAdminReqName + `/v1/mng/ho/${data.siteSeq}/${data.dongCode}/${data.hoCode}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigDonghoExcelDown: function (_context, [siteSeq, siteName, dongCode, hoCode, callback]) { // 동호리스트 엑셀다운
    var param = '?searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDongCode=' + dongCode + '&searchHoCode=' + hoCode
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/mng/ho/bundle' + param,
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigDonghoBundleInsert: function (_context, [file, callback]) { // 동호 일괄등록
    const fileData = file
    console.log('adminConfigSiteBundleInsert', fileData)
    if (file !== undefined && file !== null && file !== '' && file.size !== undefined && file.size > 0) {
      const axiosOption = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: bundleTimeout
      }
      const data = new FormData()
      data.append('file', fileData)
      Vue.axios.post('/' + backendAdminReqName + '/v1/mng/ho/bundle', data, axiosOption).then(callback)
    }
  },
  adminConfigDonghoInsertSampleExcelDown: function (_context, callback) { // 동호 일괄등록 양식 엑셀다운
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/mng/ho/bundle/sample',
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 현장관리 - 설정 - 장비
  adminConfigEquipList: function (store, [siteSeq, siteName, deviceId, deviceName, inoutName, inoutGroupId, pageNum, rowCount, callback]) { // 현장설치 - 장비 조회
    console.log('adminConfigEquipList', siteSeq, siteName, deviceId, deviceName, inoutName, inoutGroupId, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDeviceId=' + deviceId + '&searchDeviceName=' + deviceName + '&searchInoutName=' + inoutName + '&searchInoutGroupId=' + inoutGroupId
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/mng/device' + param }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigEquipDetail: function (store, [deviceId, callback]) { // 장비 상세
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/mng/device/' + deviceId }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigEquipInsert: function (_context, [data, callback]) { // 장비 등록
    console.log('adminConfigEquipInsert', data)
    const axiosOption = { method: 'POST', url: '/' + backendAdminReqName + '/v1/mng/device', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigEquipUpdate: function (_context, [data, callback]) { // 장비 수정
    console.log('adminConfigEquipUpdate', data)
    const axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + `/v1/mng/device/${data.deviceId}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigEquipDelete: function (_context, [data, callback]) { // 장비 삭제
    console.log('adminConfigEquipDelete', data)
    const axiosOption = { method: 'DELETE', url: '/' + backendAdminReqName + `/v1/mng/device/${data.deviceId}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigEquipExcelDown: function (_context, [siteSeq, siteName, deviceId, deviceName, inoutName, callback]) { // 장비리스트 엑셀다운
    var param = '?searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchDeviceId=' + deviceId + '&searchDeviceName=' + deviceName + '&searchInoutName=' + inoutName
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/mng/device/bundle' + param,
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigEquipBundleInsert: function (_context, [file, callback]) { // 장비 일괄등록
    const fileData = file
    console.log('adminConfigSiteBundleInsert', fileData)
    if (file !== undefined && file !== null && file !== '' && file.size !== undefined && file.size > 0) {
      const axiosOption = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: bundleTimeout
      }
      const data = new FormData()
      data.append('file', fileData)
      Vue.axios.post('/' + backendAdminReqName + '/v1/mng/device/bundle', data, axiosOption).then(callback)
    }
  },
  adminConfigEquipInsertSampleExcelDown: function (_context, callback) { // 장비 일괄등록 양식 엑셀다운
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/mng/device/bundle/sample',
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },
  // 현장관리 - 설정 - 출입권한
  adminConfigInoutList: function (store, [siteSeq, siteName, inoutId, inoutName, pageNum, rowCount, callback]) { // 현장설치 - 출입권한 조회
    console.log('adminConfigInoutList', siteSeq, siteName, inoutId, inoutName, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchInoutId=' + inoutId + '&searchInoutName=' + inoutName
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/mng/inout' + param }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigInoutDetail: function (store, [deviceId, callback]) { // 출입권한 상세
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/mng/inout/' + deviceId }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigInoutInsert: function (_context, [data, callback]) { // 출입권한 등록
    console.log('adminConfigInoutInsert', data)
    const axiosOption = { method: 'POST', url: '/' + backendAdminReqName + '/v1/mng/inout', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigInoutUpdate: function (_context, [data, callback]) { // 출입권한 수정
    console.log('adminConfigInoutUpdate', data)
    const axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + `/v1/mng/inout/${data.inoutSeq}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigInoutDelete: function (_context, [data, callback]) { // 출입권한 삭제
    console.log('adminConfigInoutDelete', data)
    const axiosOption = { method: 'DELETE', url: '/' + backendAdminReqName + `/v1/mng/inout/${data.inoutSeq}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigInoutExcelDown: function (_context, [siteSeq, siteName, inoutId, inoutName, callback]) { // 출입권한리스트 엑셀다운
    var param = '?searchSiteSeq=' + siteSeq + '&searchSiteName=' + siteName + '&searchInoutId=' + inoutId + '&searchInoutName=' + inoutName
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/mng/inout/bundle' + param,
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigInoutBundleInsert: function (_context, [file, callback]) { // 출입권한 일괄등록
    const fileData = file
    console.log('adminConfigSiteBundleInsert', fileData)
    if (file !== undefined && file !== null && file !== '' && file.size !== undefined && file.size > 0) {
      const axiosOption = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        timeout: bundleTimeout
      }
      const data = new FormData()
      data.append('file', fileData)
      Vue.axios.post('/' + backendAdminReqName + '/v1/mng/inout/bundle', data, axiosOption).then(callback)
    }
  },
  adminConfigInoutInsertSampleExcelDown: function (_context, callback) { // 출입권한 일괄등록 양식 엑셀다운
    const axiosOption = {
      method: 'GET',
      url: '/' + backendAdminReqName + '/v1/mng/inout/bundle/sample',
      // responseType: 'arraybuffer'
      responseType: 'blob',
      timeout: bundleTimeout
    }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigCompanyList: function (store, [companyName, pageNum, rowCount, callback]) { // 건설사 조회
    console.log('adminConfigCompanyList', companyName, pageNum, rowCount)
    var param = '?pageNum=' + pageNum + '&rowCount=' + rowCount + '&searchCompanyName=' + companyName
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/mng/company' + param }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigCompanyDetail: function (store, [siteSeq, callback]) { // 건설사 상세
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/mng/company/' + siteSeq }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigCompanyInsert: function (_context, [data, callback]) { // 건설사 등록
    console.log('adminConfigCompanyInsert', data)
    const axiosOption = { method: 'POST', url: '/' + backendAdminReqName + '/v1/mng/company', data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigCompanyUpdate: function (_context, [data, callback]) { // 건설사 수정
    console.log('adminConfigCompanyUpdate', data)
    const axiosOption = { method: 'PATCH', url: '/' + backendAdminReqName + `/v1/mng/company/${data.companySeq}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },
  adminConfigCompanyDelete: function (_context, [data, callback]) { // 건설사 삭제
    console.log('adminConfigCompanyDelete', data)
    const axiosOption = { method: 'DELETE', url: '/' + backendAdminReqName + `/v1/mng/company/${data.companySeq}`, data: data }
    Vue.axios(axiosOption).then(callback)
  },

  // common list
  searchSiteList: function (_context, [callback]) { // 현장리스트 조회
    console.log('searchSiteList')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/com/site' }
    Vue.axios(axiosOption).then(callback)
  },
  searchAccessList: function (_context, [siteSeq, callback]) { // 현장별 전체 출입권한 조회
    console.log('searchAccessList')
    var param = '?searchSiteSeq=' + siteSeq
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/com/inout' + param }
    Vue.axios(axiosOption).then(callback)
  },
  searchEquipList: function (_context, [siteSeq, callback]) { // 현장별 전체 장비리스트 조회
    console.log('searchEquipList')
    var param = '?searchSiteSeq=' + siteSeq
    const regex = new RegExp('=null', 'g')
    param = param.replace(regex, '=')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/com/device' + param }
    Vue.axios(axiosOption).then(callback)
  },
  searchIconList: function (_context, callback) { // 전체 현장아이콘 리스트 조회
    console.log('searchIconList')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/com/site/icon' }
    Vue.axios(axiosOption).then(callback)
  },
  searchCompanyList: function (_context, [callback]) { // 건설사리스트 조회
    console.log('searchCompanyList')
    const axiosOption = { method: 'GET', url: '/' + backendAdminReqName + '/v1/com/company' }
    Vue.axios(axiosOption).then(callback)
  }

}
