// 상태조회
export default {
  // 로그인 정보 조회
  getLoginInfo: function (state) {
    return state.loginInfo
  },
  getMenuList: function (state) {
    return state.menus
  },
  getSecondMenuList: function (state) {
    return state.menus.secondMenu
  },
  getMenuMoveFlag: function (state) {
    return state.menus.menuMoveFlag
  }
}
