'use strict'

import Vue from 'vue'
import axios from 'axios'
import store from '@/vuex/store'
import router from '@/router'
import { i18n } from '../i18n'

// import AxiosPlugin from 'vue-axios-cors'
// Vue.use(AxiosPlugin)

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || ''
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URI
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.get['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.put['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.delete['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.headers.patch['Content-Type'] = 'application/json; charset=utf-8'
axios.defaults.preflightContinue = true
axios.defaults.crossDomain = true
axios.defaults.withCredentials = true

const https = require('https')
let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  withCredentials: true, // Check cross-site Access-Control
  baseURL: process.env.VUE_APP_BASE_URI || '',
  timeout: 30 * 1000,
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
}
// router lazy loading - loading chunk failed error 대응
router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
  // const pattern = /Loading chunk (\d)+ failed/g;
  // const isChunkLoadFailed = error.message.match(pattern);
  // const targetPath = router.history.pending.fullPath;
  // if (isChunkLoadFailed) {
  //   router.replace(targetPath);
  // }
})

const _axios = axios.create(config)

// 요청 데이타 암호화 처리하여 전송
_axios.interceptors.request.use(
  function (config) {
    // // config.headers.Authorization = localStorage.getItem('token')
    store.state.axiosResFlag = false
    // 0.3초 안에 응답이 오지 않을 경우 로딩아이콘 표기하기
    setTimeout(function () {
      if (!store.state.axiosResFlag && !store.state.axiosLoadingSkipFlag) {
        store.state.axiosLoading = true
      }
    }, 100)

    // 로그인 키가 존재 시 헤더 삽입하기
    var loginKey = store.state.loginInfo.loginKey
    if (loginKey !== '') {
      config.headers['loginKey'] = loginKey
    }
    config.headers['axios'] = true

    // console.log('request headers', JSON.stringify(config.headers))
    // console.log('request data', config.data)

    // 요청 정보 암호화하기
    if (!store.state.fileUploading) {
      var encKey = store.state.loginInfo.encKey
      if (encKey === undefined || encKey === '') {
        encKey = store.state.basicEncKey
      }
      // console.log('encKey : ', encKey)

      var key = window.getHex(encKey)
      var encData = window.getEncryptData(key, window.getHex(store.state.basicEncIv), JSON.stringify(config.data))
      // var encData = config.data
      var bodyParam = {
        'data': encData
      }
      config.data = JSON.stringify(bodyParam)
    }
    return config
  },
  function (error) {
    // 요청 시 에러 발생하면 에러 팝업 띄우기
    store.state.axiosResFlag = true
    store.state.axiosLoading = false
    store.state.tableLoading = false
    store.state.fileUploading = false
    store.dispatch('showErrorDialog', { title: 'Client Error', message: i18n.t('error.clientReqError') })
    console.log('request error - ', error)
    return Promise.reject(error)
  }
)

// 응답 데이터 암호화 처리하여 전송
_axios.interceptors.response.use(
  function (response) {
    if (!store.state.axiosLoadingSkipFlag) {
      store.state.axiosResFlag = true
      store.state.axiosLoading = false
    }
    store.state.fileUploading = false

    // 응답 데이터 복호화하기
    var data = response.data.data
    // console.log('response encrypt data : ', data)

    // blob 파일명 변환
    if (response.config.responseType === 'blob' || response.config.responseType === 'arraybuffer') {
      const contentDisposition = response.headers['content-disposition'] // 파일 이름
      let fileName = ''
      if (contentDisposition) {
        const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'))
        if (fileNameMatch) {
          [ , fileName ] = fileNameMatch.split('=')
        }
      }
      fileName = decodeURI(fileName) // uri 변환
      // console.log('response headers fileName', fileName)
      response.fileName = fileName
    }
    if (data !== undefined) {
      var encKey = store.state.loginInfo.encKey
      if (encKey === '') {
        encKey = store.state.basicEncKey
      }
      try {
        var key = window.getHex(encKey)
        var decData = window.getDecryptData(key, window.getHex(store.state.basicEncIv), data)
        // console.log('response decrypt data : ' + decData)
        response.data = JSON.parse(decData)
        // response.data = data
      } catch (e) {
        // console.log(e)
        // router.push({ path: store.state.loginAuthPath })
        router.push({ path: '/login' })
        // if (store.state.mainInfo.loginKind === 2) {
        //   router.push({ path: '/loginAdmin' })
        // } else {
        //   router.push({ path: '/login' })
        // }
      }
    }
    return response
  },
  function (error) {
    store.state.axiosResFlag = true
    store.state.axiosLoading = false
    store.state.tableLoading = false
    console.log('error : ', JSON.stringify(error))
    var flag = false
    if (error.response !== undefined) {
      if (error.response.status !== undefined) {
        flag = true
      }
    }
    if (!flag) {
      const location = window.location.href
      console.log('location', location)
      if (location.indexOf('/rtlsapt') >= 0) {
        store.dispatch('showErrorDialog', { title: 'Server Error', message: i18n.t('error.500') })
        router.push({ path: '/login' })
        // if (store.state.mainInfo.loginKind === 2) {
        //   router.push({ path: '/loginAdmin' })
        // } else {
        //   router.push({ path: '/login' })
        // }
      } else {
        store.dispatch('showErrorDialog', { title: i18n.t('error.timeoutTitle'), message: i18n.t('error.timeoutError') })
      }
      return Promise.reject(error)
    }
    if (error.response.status === 401) { // 로그인 키 문제 발생
      router.go('login')
      // if (store.state.mainInfo.loginKind === 2) {
      //   router.go('/loginAdmin')
      // } else {
      //   router.go('/login')
      // }
    } else { // 기타 오류 발생
      var msg = ''
      if (error.response.status === 504) {
        msg = i18n.t('error.504')
      } else if (error.response.status === 404) {
        msg = i18n.t('error.404')
      } else if (error.response.status === 400) {
        msg = i18n.t('error.400')
      } else {
        msg = i18n.t('error.500')
      }
      store.dispatch('showErrorDialog', { title: 'Server Error', message: msg })
    }
    console.log('response error - ', error)
    return Promise.reject(error)
  }
)

const AxiosPlugin = {
  install (Vue, options) {
    Vue.axios = _axios
    window.axios = _axios

    Object.defineProperties(Vue.prototype, {
      axios: {
        get () {
          return _axios
        }
      },
      $axios: {
        get () {
          return _axios
        }
      }
    })
  }
}
Vue.use(AxiosPlugin)

export default AxiosPlugin

/*
Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
*/
