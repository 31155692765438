var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"loginBG pa-0",attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{class:'align-center',attrs:{"justify-center":""}},[_c('div',{staticClass:"topBG hidden-md-and-up"}),_c('div',{staticClass:"bottomBG hidden-md-and-up"}),_c('v-flex',[_c('ValidationObserver',{ref:"observer",staticStyle:{"width":"100%"},attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var passes = ref.passes;
return [_c('v-form',{attrs:{"id":"loginForm"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.loginSubmit)}}},[_c('v-card',{staticClass:"form"},[_c('v-card-title',{attrs:{"loginForm":""}},[_c('div',[_c('span',{staticClass:"title_top"},[_vm._v("Site Adminstration System")]),_c('span',{staticClass:"title_middle"},[_vm._v(_vm._s(_vm.$store.state.siteTitleName))]),_c('span',{staticClass:"title_bottom"})])]),_c('v-card-text',{attrs:{"loginForm":""}},[_c('v-spacer'),_c('v-row',{staticStyle:{"min-height":"58px"},attrs:{"no-gutters":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"user.id","rules":"required|min:4|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('g-text-field',{ref:"userId",attrs:{"label":_vm.$t('fields.user.id'),"id":"id","name":"id","value":_vm.user.id,"tabindex":1,"maxlength":20,"rounded":true,"singleLine":true,"errors":errors},model:{value:(_vm.user.id),callback:function ($$v) {_vm.$set(_vm.user, "id", $$v)},expression:"user.id"}},[_c('template',{slot:"prependInnerIcon"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("$vuetify.icons.user")])],1)],2)]}}],null,true)})],1)],1),_c('v-row',{staticStyle:{"min-height":"58px"},attrs:{"no-gutters":""}},[_c('v-col',[_c('ValidationProvider',{ref:"user.password",attrs:{"name":"user.pw","rules":"required|min:4|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('g-text-field',{ref:"userPassword",attrs:{"type":_vm.show1 ? 'text' : 'password',"appendIcon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"label":_vm.$t('texts.passwordEng'),"id":"password","name":"password","value":_vm.user.password,"tabindex":2,"maxlength":12,"errors":errors,"rounded":true,"singleLine":true},on:{"append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}},[_c('template',{slot:"prependInnerIcon"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("$vuetify.icons.pw")])],1)],2)]}}],null,true)})],1)],1),_c('v-row',{staticStyle:{"min-height":"68px"},attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"margin-left":"16px"}},[_c('v-checkbox',{staticClass:"mt-0 pt-0",staticStyle:{"font-size":"14px !important"},attrs:{"label":_vm.$t('texts.idSaveFlag'),"color":'#3ba8be',"dense":""},model:{value:(_vm.idSaveFlag),callback:function ($$v) {_vm.idSaveFlag=$$v},expression:"idSaveFlag"}})],1)],1)],1),_c('v-divider',{staticStyle:{"margin":"0 33px 0 33px"}}),_c('v-card-actions',{attrs:{"loginForm":""}},[_c('v-btn',{staticStyle:{"height":"40px","font-size":"20px !important"},attrs:{"loginBtn":"","type":"submit","form":"loginForm"}},[_vm._v(" LOG IN "),_c('img',{staticClass:"svg_icon ml-1",attrs:{"src":_vm.images.arrow_r}})])],1)],1)],1)]}}])})],1),_c('g-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }