import _ from 'lodash'
// 상태 변경
export default {
  setConnectInfo: function (state, info) { // connect information save
    state.connectInfo = info
  },
  setLoginInfo: function (state, info) { // login information save
    state.loginInfo = info
  },
  setMenuInfo: function (state, [menuList]) {
    const _findMenuByPath = (menuPath) => {
      let menuIdx = 0
      let _isExist = _.some(menuList, depth1 => {
        menuIdx++
        return _.some(_.map(depth1.children, 'menuPath'), path => path.indexOf(menuPath) > -1)
      })
      if (_isExist) {
        return menuList[menuIdx - 1]
      } else {
        return undefined
      }
    }

    let systemMenu = _findMenuByPath('/system')
    var headerMenu = menuList.filter(item => {
      if (!systemMenu) {
        return item
      } else {
        return systemMenu ? (item.menuSeq !== systemMenu.menuSeq) : systemMenu
      }
    })
    state.menus = systemMenu ? headerMenu : menuList
    if (systemMenu) { state.settingMenus = [systemMenu] }
    sessionStorage.setItem('session_menuInfo', JSON.stringify({
      settingMenu: systemMenu ? [systemMenu] : undefined,
      headerMenu: headerMenu
    }))
  },
  setSecondMenuList: function (state, info) {
    state.menus.secondMenu = info
  },
  setCurrentMenu1Title: function (state, info) {
    state.currentMenu1Title = info
  },
  setCurrentMenu: function (state, info) {
    state.currentMenu = info
  },
  setMainMenuShowDisable: function (state) {
    state.menuOverlay = false
    // state.mainMenuShow = false
  }
}
