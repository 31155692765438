import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import 'vuetify/src/stylus/app.styl'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
// import MaterialIcon from '@/components/MaterialIcon'
// import { Scroll } from 'vuetify/lib/directives'
// import '@fortawesome/fontawesome-free/css/all.css'

// function missingMaterialIcons (ids) {
//   const icons = {}
//   for (const id of ids) {
//     for (const suffix of ['fill', 'outline', 'two-tone', 'round', 'sharp']) {
//       const name = `${id}_${suffix}`
//       icons[name] = {
//         component: MaterialIcon,
//         props: {
//           name
//         }
//       }
//     }
//   }
//   return icons
// }
import { Ripple } from 'vuetify/lib/directives'
import temp from '@/components/icons/temp'
import rain from '@/components/icons/rain'
import dust from '@/components/icons/dust'
import user from '@/components/icons/user'
import pw from '@/components/icons/pw'

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
})

// Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    // ...missingMaterialIcons(['visibility', 'visibility_off'])
    iconfont: 'mdi',
    values: {
      temp: { component: temp },
      rain: { component: rain },
      dust: { component: dust },
      user: { component: user },
      pw: { component: pw }
    }
  }
})
