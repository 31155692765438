import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import actions from './actions'
// test용 액션 처리(백엔드랑 연동x)
// import actions from './actionsTest'

import mutations from './mutations'

Vue.use(Vuex)

const state = {
  loginInfo: { // 로그인 정보
    loginKey: '', // 로그인 키
    encKey: '' // 암호화 키
  },
  loginAuthPath: '/login', // 로그인메뉴 경로 - login / loginAdmin
  menus: [], // 사용자 메뉴
  settingMenus: [], // 설정 메뉴
  userInfoDialog: false, // 메인화면 사용자정보 팝업표시
  userUpdateFlag: false, // 메인화면 사용자정보 수정표시
  isRouterAlive: true,
  menuMoveFlag: true,
  currentMenuSeq: null,
  currentMenu: [],
  currentMenuTitle: '', // 현재메뉴명칭
  currentMenuPath: '', // 현재메뉴경로
  currentMenu1Title: '', // 현재메뉴1명칭
  currentMenu2Title: '', // 현재메뉴2명칭
  currentMenu3Title: '', // 현재메뉴3명칭
  mainMenuShow: true, // 메인메뉴 표시 시 왼쪽메뉴 표시 막기 위한 flag
  menuOverlay: true, // 왼쪽메뉴 표시 여부
  menu1Seq: 10000, // 현재메뉴1시퀀스
  menu2Seq: 0, // 현재메뉴2시퀀스
  menu3Seq: 0, // 현재메뉴3시퀀스
  mainInfo: {
    id: '', // ID
    userName: '', // 사용자명
    groupName: '', // 그룹명
    groupGrade: 2, // 그룹권한,
    loginKind: 1, // 로그인유형 - 1: 관리사무소, 2: 현장관리
    mainSiteList: [], // 관리가능한 현장리스트
    selectedMainSiteSeq: null // 현재 선택한 현장 seq
  },
  connectInfo: { // 접속 정보 (최초 접속 시 체크)
    deviceType: '', // 접속 종류(anroid, ios, pc)
    browser: '', // 브라우저 종류(Internet Explorer, Chrome, Opera, Firefox, Edge, Safari, Edge)
    version: '' // 브라우저 버전
  },
  guideTypeCode: '', // 활성화된 사용안내 분류코드(메뉴 이동시 변경)
  basicEncKey: 'GY-KACS-RTLSAPT-A13D31#-CF1294DE', // 기본 암호화 키
  basicEncIv: 'GY-KACS-RTLSAPT-', // 기본 iv 키
  dialogTimer: null, // dialog timer obj
  dialogErrorFlag: false, // 에러 발생 여부
  dialogErrorTitle: '', // 에러 발생 시 dialog title
  dialogErrorMsg: '', // 에러 발생 시 dialog 내용
  axiosResFlag: false, // axios 응답 여부
  axiosLoading: false, // axios loading bar 표현 여부(응답이 0.5초 이상 걸릴 경우 표현)
  axiosLoadingSkipFlag: false, // axios 요청 중 loading 표시하지 않을지 여부
  snackbarFlag: false, // snackbar 발생 여부(유효성 체크, 성공 실패 결과)
  snackbarMsg: '', // snackbar 발생 내용
  snackbarColor: '', // snackbar 색상
  snackbarTop: true, // snackbar top 위치 여부
  snackbarBottom: false, // snackbar bottom 위치 여부
  snackbarRight: true, // snackbar right 위치 여부
  snackbarVertical: false, // snackbar vertical 여부
  snackbarAutoHeight: true, // snackbar auto height 여부
  windowWidth: window.innerWidth, // window width
  siteTitleName: '', // 사이트 명
  siteName: '', // 현장 아파트 명
  loginTitle: '',
  loginSubtitle: '',
  menuTitle: '',
  deliveryUseFlag: 0,
  contentsTitle: '', // 컨텐츠 타이틀명
  contentsTitleShow: true, // 타이틀 영역 감추기
  contentsDragFlag: false,
  tableLoading: false, // 리스트 로딩 표시
  fileUploading: false, // 파일업로드 중 동작막음, 비암호화처리
  detailFlag: false // 상세폼 표시여부
}

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions
})
