<template>
  <!-- <v-app style="height: 100%;min-width: 1460px;"> -->
  <v-app style="height: 100%;">
    <router-view/>
    <g-dialog-confirm :model="$store.state.dialogErrorFlag" :actionType="'close'" :iconName="'caution'" :text="$store.state.dialogErrorMsg" @click:close="dialogErrorClose"></g-dialog-confirm>

    <!-- loading -->
    <v-dialog v-model="this.$store.state.axiosLoading" persistent fullscreen transition="false">
      <v-container fill-height>
        <v-layout row justify-center align-center>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <!-- bottom snackbar popup -->
    <v-snackbar id="appSnackbar" dark v-model="$store.state.snackbarFlag" @input="snackbarClose" :color="getSnackbarColor()" class="v-snack--has-background"
     :multi-line="$store.state.snackbarAutoHeight" :right="$store.state.snackbarRight" :bottom="$store.state.snackbarBottom"
      :timeout="6000" :top="$store.state.snackbarTop" :vertical="$store.state.snackbarVertical" app>
      <v-row no-gutters align="center">
        <!-- <v-col> -->
          <img :src="getSnackbarIcon($store.state.snackbarColor)" class="snackbarIcon"/>
        <!-- </v-col> -->
        <v-col align-self="center">
          {{ this.$store.state.snackbarMsg }}
        </v-col>
        <!-- <v-col> -->
          <v-btn class="snackbarBtn close" icon @click.native="snackbarClose" color="#FFF"><v-icon light class="close"></v-icon></v-btn>
        <!-- </v-col> -->
      </v-row>
    </v-snackbar>
  </v-app>
</template>

<script>
import GDialogConfirm from '@/components/GDialogConfirm'

export default {
  name: 'App',
  components: {
    GDialogConfirm
  },
  data: () => ({
    snackbarIcons: {
      'error': require('@/assets/images/sub/icon-snackbar-minus.svg'),
      'success': require('@/assets/images/sub/icon-snackbar-confirm.svg')
    }
  }),
  watch: {
    // 라우트 변경 시 처리
    '$route' (to, from) {
      // console.log('to : ' + JSON.stringify(to))
      // console.log('from : ' + JSON.stringify(from))
    }
  },
  mounted () {
    let self = this
    var agent = navigator.userAgent
    var match
    var deviceType, browser, version
    console.log(agent)

    if (/Android/i.test(navigator.userAgent)) {
      deviceType = 'android'
    } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      deviceType = 'ios'
    } else {
      deviceType = 'pc'
    }

    if ((match = agent.match(/MSIE ([0-9]+)/)) || (match = agent.match(/Trident.*rv:([0-9]+)/))) {
      browser = 'Internet Explorer'
    } else if ((match = agent.match(/OPR\/([0-9]+)/)) || (match = agent.match(/Opera\/([0-9]+)/))) {
      browser = 'Opera'
    } else if ((match = agent.match(/Chrome\/([0-9]+)/))) {
      browser = 'Chrome'
    } else if ((match = agent.match(/Firefox\/([0-9]+)/))) {
      browser = 'Firefox'
    } else if ((match = agent.match(/Safari\/([0-9]+)/))) {
      browser = 'Safari'
    } else if ((match = agent.match(/Edge\/([0-9]+)/))) {
      browser = 'Edge'
    } else {
      browser = 'Unknown'
    }

    if (browser !== 'Unknown') {
      if (browser === 'Safari') {
        version = agent.match(/Version\/([0-9]+)/)[1]
      } else {
        version = match[1]
      }
    }
    // console.log('siteTitleName : ' + process.env.VUE_APP_SITE_TITLE_NAME)
    // self.$store.state.siteTitleName = process.env.VUE_APP_SITE_TITLE_NAME || 'HOME AUTO SYSTEM'
    self.$store.state.siteTitleName = process.env.VUE_APP_SITE_TITLE_NAME
    console.log('deviceType : ' + deviceType)
    console.log('Browser: ' + browser)
    console.log('Version: ' + version)
    self.$store.commit('setConnectInfo', { deviceType: deviceType, browser: browser, version: version })
    self.connectCheck(deviceType, browser, version)
  },
  methods: {
    connectCheck: function (deviceType, browser, version) {
      var msg = ''
      if (deviceType === 'pc') {
        if (browser === 'Internet Explorer' && version < 10) {
          msg = this.$t('error.browser', { browser: browser, version: 10 })
        } else if (browser === 'Opera' && version < 30) {
          msg = this.$t('error.browser', { browser: browser, version: 30 })
        } else if (browser === 'Chrome' && version < 45) {
          msg = this.$t('error.browser', { browser: browser, version: 45 })
        } else if (browser === 'Firefox' && version < 38) {
          msg = this.$t('error.browser', { browser: browser, version: 38 })
        } else if (browser === 'Safari' && version < 9) {
          msg = this.$t('error.browser', { browser: browser, version: 9 })
        } else if (browser === 'Edge' && version < 12) {
          msg = this.$t('error.browser', { browser: browser, version: 12 })
        } else if (browser === 'Unknown') {
          msg = this.$t('error.unknownBrowser')
        }
      }
      console.log(msg)
      if (msg !== '') {
        try {
          this.$store.dispatch('showErrorDialog', { title: 'Browser Error', message: msg, autoClose: false })
        } catch (e) {
          alert(msg.replace(/(<br>|<br\/>|<br \/>)/g, '\r\n'))
        }
      }
    },
    dialogErrorClose: function () {
      this.$store.dispatch('closeErrorDialog')
    },
    snackbarClose: function () {
      this.$store.dispatch('snackbarClose')
    },
    getSnackbarIcon: function (type) {
      return this.snackbarIcons[type]
    },
    getSnackbarColor: function () {
      return this.$store.state.snackbarColor === 'error' ? '#d25f42' : '#1d3246'
    }
  }
}
</script>

<style scoped>
.brower-error-content {height:150px;padding:30px 16px;font-weight:500;font-family: 'NotoSansCJKKR';}
</style>

<style>
/* .v-snack.v-snack--active {padding: 8px 8px 0 8px;} */
.v-application .success {background-color: #1d3246 !important; border-color: #1d3246 !important; color: #FFF;}
.v-application .error {background-color: #d25f42 !important;border-color: #d25f42 !important; color: #FFF;}
.v-snack__wrapper {min-width: 400px; max-width: 720px; border-radius: 5px; font-size: 14px !important; text-align: left !important;}
.v-snack__wrapper.success {background-color: #1d3246 !important; border-color: #1d3246 !important; color: #FFF;}
.v-snack__wrapper.error {background-color: #d25f42 !important;border-color: #d25f42 !important; color: #FFF;}
/* .v-snack__wrapper .img {width: 32px; height: 32px;border: 2px solid #fff; border-radius: 25px;margin-right:16px;} */
/* .v-snack__wrapper .close {width: 24px; height: 24px;background: url('~@/assets/images/sub/icon-close.svg') no-repeat;position: relative;top:-24px;margin-right:8px;} */
.v-snack__wrapper.error .img {background: url('~@/assets/images/sub/icon_error.svg') no-repeat center center;}
.v-snack__wrapper.success .img {background: url('~@/assets/images/sub/icon_ok.svg') no-repeat center center;}
/* .v-snack--multi-line .v-snack__content {padding:32px 0 32px 16px;} */
.v-snack__content .v-btn.close {background-color: unset !important;}
.success .material-icons.theme--light { color: rgba(255, 255, 255, 1); }

#appSnackbar {font-weight: normal !important; color: #FFF;}
.confirmSnackbar {background: #1d3246 !important;}
.errorSnackbar {background: #d25f42 !important;}
.snackbarIcon {padding-right: 16px;}
.snackbarBtn {margin-left: 16px !important;}
/* @media (min-width: 600px) {
  .v-snack.v-snack--active {padding: 0;}
  .v-snack__wrapper {border-radius: 5px;box-shadow: 0 2px 4px 0 #00000033;margin: 0 auto !important;margin-right: 0 !important;}
  .v-snack--multi-line .v-snack__content {min-width: 359px !important;}
} */
</style>
