<template>
  <g-dialog :model="dialog" @close="closeDialog($event)" :width="width" :title="title">
    <template v-slot:title>
      <slot name="title">
        <v-row class="confirm-popup-text" style='width:100%;'>
          <v-col class="confirm-popup-text_msg pa-0" style="text-align: left;" v-html="title"></v-col>
        </v-row>
      </slot>
    </template>
    <template v-slot:text>
      <v-row no-gutters v-if="xIcon && getIconName() !== undefined">
        <v-col class="pa-4" style="text-align: center;">
          <img :src="getIconName()" class="dialogIcon"/>
        </v-col>
      </v-row>
      <slot name="text">
        <v-row class="confirm-popup-text">
          <v-col class="confirm-popup-text_msg pt-0 mb-4" style="text-align: center;" v-html="text"></v-col>
        </v-row>
      </slot>
    </template>
    <template v-slot:actions>
      <slot name="actions">
        <v-row no-gutters v-if="actionType === 'close'" style="height: 48px;">
          <v-col>
            <v-btn color="#1d3246" dark block tile @click="closeDialog($event)"><!-- <v-icon>mdi-check</v-icon> --><!-- <img :src="btnIcons['confirm']" class="dialogIcon"/> -->{{ $t('btns.close') }}</v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters v-else style="height: 48px;">
          <template v-if="actionType === 'delete'">
            <v-col>
              <v-btn color="#d25f42" dark block tile @click="confirmOk"><img :src="btnIcons['delete']" class="dialogIcon"/>{{ $t('btns.delete') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn color="#1d3246" dark block tile @click="closeDialog($event)"><!-- <v-icon>mdi-close</v-icon> --><img :src="btnIcons['cancel']" class="dialogIcon"/>{{ $t('btns.cancel') }}</v-btn>
            </v-col>
          </template>
          <template v-else-if="actionType === 'leave'">
            <v-col>
              <v-btn color="#d25f42" dark block tile @click="confirmOk"><img :src="btnIcons['confirm']" class="dialogIcon"/>{{ $t('btns.houseInit') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn color="#1d3246" dark block tile @click="closeDialog($event)"><!-- <v-icon>mdi-close</v-icon> --><img :src="btnIcons['cancel']" class="dialogIcon"/>{{ $t('btns.cancel') }}</v-btn>
            </v-col>
          </template>
          <template v-else-if="actionType === 'out'">
            <v-col>
              <v-btn color="#2373c4" dark block tile @click="confirmOk"><img :src="btnIcons['confirm']" class="dialogIcon"/>{{ $t('btns.yes') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn color="#1d3246" dark block tile @click="closeDialog($event)"><!-- <v-icon>mdi-close</v-icon> --><img :src="btnIcons['cancel']" class="dialogIcon"/>{{ $t('btns.no') }}</v-btn>
            </v-col>
          </template>
          <template v-else-if="actionType === 'copy'">
            <v-col>
              <!-- <v-btn color="#2373c4" dark block tile @click="confirmCopy"><img :src="btnIcons['confirm']" class="dialogIcon"/>{{ $t('btns.copy') }}</v-btn> -->
              <v-btn color="#2373c4" dark block tile @click="confirmOk"><img :src="btnIcons['confirm']" class="dialogIcon"/>{{ $t('btns.copy') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn color="#1d3246" dark block tile @click="closeDialog($event)"><!-- <v-icon>mdi-close</v-icon> --><img :src="btnIcons['cancel']" class="dialogIcon"/>{{ $t('btns.close') }}</v-btn>
            </v-col>
          </template>
          <template v-else>
            <v-col>
              <v-btn color="#2373c4" dark block tile @click="confirmOk"><!-- <v-icon>mdi-check</v-icon> --><img :src="btnIcons['confirm']" class="dialogIcon"/>{{ $t('btns.yes') }}</v-btn>
            </v-col>
            <v-col>
              <v-btn color="#1d3246" dark block tile @click="closeDialog"><!-- <v-icon>mdi-close</v-icon> --><img :src="btnIcons['cancel']" class="dialogIcon"/>{{ $t('btns.no') }}</v-btn>
            </v-col>
          </template>
        </v-row>
      </slot>
    </template>
  </g-dialog>
</template>

<script>
import gDialog from '@/components/GDialog'

export default {
  name: 'GDialogConfirm',
  components: {
    gDialog
  },
  props: {
    model: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: false
    },
    xIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    iconName: {
      type: String,
      required: false
    },
    width: {
      require: false,
      default: 410
    },
    actionType: {
      type: String,
      require: false,
      default: 'caution'
    }
  },
  data: () => ({
    dialog: false,
    icons: {
      'caution': require('@/assets/images/sub/icon-popup-caution.svg'),
      'confirm': require('@/assets/images/sub/icon-popup-confirm.svg'),
      'out': require('@/assets/images/sub/icon-popup-confirm.svg'),
      'delete': require('@/assets/images/sub/icon-popup-delete.svg'),
      'logout': require('@/assets/images/sub/icon-popup-logout.svg'),
      'minus': require('@/assets/images/sub/icon-popup-minus.svg'),
      'leave': require('@/assets/images/sub/icon-popup-confirm.svg'),
      'withdraw': require('@/assets/images/sub/icon-popup-delete.svg'),
      'copy': require('@/assets/images/sub/icon-popup-confirm.svg')
    },
    btnIcons: {
      'update': require('@/assets/images/sub/edit.svg'),
      'cancel': require('@/assets/images/sub/close.svg'),
      'delete': require('@/assets/images/sub/delete.svg'),
      'confirm': require('@/assets/images/sub/check.svg'),
      'withdraw': require('@/assets/images/sub/check.svg')
    }
  }),
  created () {
  },
  mounted () {
  },
  watch: {
    model (val) {
      this.dialog = val
    }
  },
  computed: {
  },
  methods: {
    closeDialog: function (e) {
      if (e !== undefined) {
        e.preventDefault()
        e.stopPropagation()
      }
      this.$emit('click:close')
    },
    confirmOk: function (e) {
      e.preventDefault()
      this.$emit('click:ok')
    },
    confirmCopy: function (e, dataText) {
      e.preventDefault()
      // console.log('confirmCopy')
      const el = document.createElement('textarea')
      el.addEventListener('focusin', e => e.stopPropagation())
      el.value = dataText
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    getBtnColor: function () {
      return this.actionType === 'confirm' ? '#2373c4' : '#d25f42'
    },
    getIconName: function () {
      return this.iconName !== null && this.iconName !== undefined && this.iconName !== '' ? this.icons[this.iconName] : this.icons[this.actionType]
    }
  }
}
</script>

<style scoped>
IMG {margin-right: 4px;}
.confirm-popup-text > .confirm-popup-text_icon {height:56px;}
.confirm-popup-text > .confirm-popup-text_msg {font-size: 20px;font-style: normal;font-stretch: normal;line-height: normal;letter-spacing: normal;text-align: center;color: #444;}
.confirm-popup-text > .confirm-popup-text_msg > .penaltyText {margin-top:20px;color:#e56747;}
.v-btn {font-size: 1rem !important;}
</style>
