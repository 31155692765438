import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { i18n } from './i18n'
// import { split } from 'core-js/fn/symbol'

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`)

    return i18n.t(`validation.${values._rule_}`, values)
  }
})

// 전화번호 유효성 규칙
const phoneRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid phone number`
  },
  validate (value, args) {
    const PHONEREG = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/
    return PHONEREG.test(value)
  }
}

// 비밀번호 유효성 규칙
const passRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid password`
  },
  validate (value, args) {
    const PASSREG = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@@#$%^&*]).*$/
    return PASSREG.test(value)
  }
}

// 출입비밀번호 유효성 규칙
const inoutPassRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid password`
  },
  validate (value, args) {
    const PASSREG = /(.)\1\1/
    if (!PASSREG.test(value)) {
      var charList = ('' + value).split('')

      var increaseCount = 1
      for (var i = 1; i < charList.length; i++) {
        if (parseInt(charList[i]) === parseInt(charList[i - 1]) + 1) {
          increaseCount++
        } else {
          increaseCount = 1
        }
      }
      // console.log('increaseCount=' + increaseCount)
      if (increaseCount < 4) {
        return true
      }
    }
    return false
  }
}

// 카드ID 유효성 규칙
const cardIdRule = {
  getMessage (field, args) {
    // return `The ${field} must be either a valid card id`
    return i18n.t('errors.InvalidType.cardId')
  },
  validate (value, args) {
    // const PASSREG = /^[0-9a-fA-F]{8}|^[0-9a-fA-F]{14}|^[0-9a-fA-F]{20}$/
    const PASSREG = new RegExp(/^[0-9a-fA-F]{8}$|^[0-9a-fA-F]{14}$|^[0-9a-fA-F]{20}$/)
    return PASSREG.test(value)
  }
}

// 개행 문자 체크
const newLineNumber = {
  validate (value, args) {
    const PASSREG = new RegExp(/\n/)
    if (PASSREG.exec(value) !== null) {
      return value.match(/\n/g).length < args
    } else {
      return true
    }
  },
  message: function (value, args) {
    return i18n.t('error.InvalidType.newLineNumber', { line: args[0] })
  }
}

// 아이디 중복 체크
const idDuplicateRule = {
  validate: (value, { other }) => {
    if (other === 1) {
      return false
    }
    return true
  },
  message: '',
  params: [{ name: 'other', isTarget: true }]
}

// 파일업로드 유효성 체크
const fileUploadRule = {
  validate: (value, { other }) => {
    if (other !== '') {
      return other
    }
    return true
  },
  params: [{ name: 'other', isTarget: true }],
  message: 'file upload error'
}

// 도메인 유효성 규칙
const urlRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid url`
  },
  validate (value, args) {
    const URLREG = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i')
    return URLREG.test(value)
  }
}

// 아이피 유효성 규칙
const ipRule = {
  getMessage (field, args) {
    return `The ${field} must be either a valid ip`
  },
  validate (value, args) {
    const IPREG = /^(1|2)?\d?\d([.](1|2)?\d?\d){3}$/
    return IPREG.test(value)
  }
}

extend('phone', phoneRule)
extend('pass', passRule)
extend('idDuplicate', idDuplicateRule)
extend('fileUpload', fileUploadRule)
extend('url', urlRule)
extend('ip', ipRule)
extend('cardId', cardIdRule)
extend('newLineNumber', newLineNumber)
extend('inoutPass', inoutPassRule)

for (let rule in rules) {
  // add the rule
  extend(rule, rules[rule])
  /*
  extend('password', {
    validate: (value, { other }) => value === other,
    message: 'The password confirmation does not match.',
    params: [{ name: 'other', isTarget: true }]
  })
  extend('date_format', {
    validate: (value) => new Promise(resolve => {
      let regex = new RegExp(/^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[0-1])$/)
      resolve({
        valid: value && regex.test(value)
      })
    }),
    message: 'date error'
  })
  */
}

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
